import React, { useCallback } from 'react';

import { useModalsContext } from '@contexts/modals';
import {
  CardItemDetailsProps,
  ConfirmationProps,
  EditGenericItemProps,
  ModalEntity,
  ModalKey,
  ReceiveShipmentIdProps,
  RequestCheckingProps,
  ResumeConfirmationProps,
  ShipmentAddItemCodeProps,
  ShipmentAttachCommentProps,
  ShipmentDetailsProps,
  ShipmentImagePreviewProps,
  ShipmentItemDetailsProps,
} from '@contexts/modals/definitions';
import { AddGenericItemModal } from '@organisms/Modals/AddGenericItemModal';
import { AddOrganizationModal } from '@organisms/Modals/AddOrganizationModal';
import { AddUserModal } from '@organisms/Modals/AddUserModal';
import { CardItemDetailsModal } from '@organisms/Modals/CardItemDetailsModal';
import { ChangePasswordModal } from '@organisms/Modals/ChangePasswordModal';
import { ConfirmationModal } from '@organisms/Modals/ConfirmationModal';
import { EditGenericItemModal } from '@organisms/Modals/EditGenericItemModal';
import { NotificationModal } from '@organisms/Modals/Notification';
import { PendingShipmentModals } from '@organisms/Modals/PendingShipment';
import { ReceiveRemarksModal } from '@organisms/Modals/ReceiveFlow/ReceiveRemarksModal';
import { ReceiveSelectCodeModal } from '@organisms/Modals/ReceiveFlow/ReceiveSelectCodeModal';
import { ReceiveShipmentDetailsModal } from '@organisms/Modals/ReceiveFlow/ReceiveShipmentDetails';
import { ReceiveShipmentIdModal } from '@organisms/Modals/ReceiveFlow/ReceiveShipmentIdModal';
import { RequestCheckingModal } from '@organisms/Modals/RequestCheckingModal';
import { ResumeConfirmationModal } from '@organisms/Modals/ResumeConfirmationModal';
import { ShipmentModals } from '@organisms/Modals/Shipment';
import { ShipmentDetailsModal } from '@organisms/Modals/ShipmentDetailsModal';

const ModalsController = () => {
  const { modals, hideModal } = useModalsContext();

  const getProps = useCallback(
    <T extends ModalEntity['props']>(modalKey: ModalKey) => {
      return {
        open: modals.findIndex((m) => m.key === modalKey) > -1,
        onClose: () => hideModal(modalKey),
        ...(modals.find((m) => m.key === modalKey)?.props as T),
      };
    },
    [hideModal, modals],
  );

  return (
    <>
      <ChangePasswordModal {...getProps(ModalKey.CHANGE_PASSWORD)} />

      <ShipmentModals.SelectAddress
        {...getProps(ModalKey.SHIPMENT_SELECT_ADDRESS)}
      />
      <ShipmentModals.SelectProducts
        {...getProps(ModalKey.SHIPMENT_SELECT_PRODUCTS)}
      />
      <ShipmentModals.AddItemCode
        {...getProps<ShipmentAddItemCodeProps>(ModalKey.SHIPMENT_ADD_ITEM_CODE)}
      />

      <ShipmentModals.ItemDetailsModal
        {...getProps<ShipmentItemDetailsProps>(ModalKey.SHIPMENT_ITEM_DETAILS)}
      />

      <ShipmentModals.SelectPackaging
        {...getProps(ModalKey.SHIPMENT_SELECT_PACKAGING)}
      />

      <ShipmentModals.Remarks {...getProps(ModalKey.SHIPMENT_REMARKS)} />

      <ShipmentModals.ImagePreview
        {...getProps<ShipmentImagePreviewProps>(
          ModalKey.SHIPMENT_IMAGE_PREVIEW,
        )}
      />

      <ShipmentModals.Resume {...getProps(ModalKey.SHIPMENT_RESUME)} />

      <ShipmentModals.AttachCommentModal
        {...getProps<ShipmentAttachCommentProps>(
          ModalKey.SHIPMENT_ATTACH_COMMENT,
        )}
      />

      <PendingShipmentModals.RequestApproval
        {...getProps(ModalKey.PENDING_SHIPMENT_REQUEST_APPROVAL)}
      />

      <PendingShipmentModals.SelectProductsModal
        {...getProps(ModalKey.PENDING_SHIPMENT_SELECT_PRODUCTS)}
      />

      <PendingShipmentModals.SelectPackagingModal
        {...getProps(ModalKey.PENDING_SHIPMENT_SELECT_PACKAGING)}
      />

      <PendingShipmentModals.ResumeModal
        {...getProps(ModalKey.PENDING_SHIPMENT_RESUME)}
      />

      <ConfirmationModal
        {...getProps<ConfirmationProps>(ModalKey.CONFIRMATION)}
      />

      <RequestCheckingModal
        {...getProps<RequestCheckingProps>(ModalKey.REQUEST_CHECKING)}
      />

      <ResumeConfirmationModal
        {...getProps<ResumeConfirmationProps>(ModalKey.RESUME_CONFIRMATION)}
      />

      <ShipmentDetailsModal
        {...getProps<ShipmentDetailsProps>(ModalKey.SHIPMENT_DETAILS)}
      />

      <AddOrganizationModal {...getProps(ModalKey.ADD_ORGANIZATION)} />

      <AddUserModal {...getProps(ModalKey.ADD_USER)} />

      <AddGenericItemModal {...getProps(ModalKey.ADD_GENERIC_ITEM)} />

      <EditGenericItemModal
        {...getProps<EditGenericItemProps>(ModalKey.EDIT_GENERIC_ITEM)}
      />

      <NotificationModal {...getProps(ModalKey.NOTIFICATION)} />

      <ReceiveSelectCodeModal {...getProps(ModalKey.RECEIVE_SELECT_CODE)} />

      <ReceiveShipmentIdModal
        {...getProps<ReceiveShipmentIdProps>(ModalKey.RECEIVE_SHIPMENT_ID)}
      />

      <ReceiveShipmentDetailsModal
        {...getProps(ModalKey.RECEIVE_SHIPMENT_DETAILS)}
      />

      <ReceiveRemarksModal {...getProps(ModalKey.RECEIVE_SHIPMENT_REMARKS)} />

      <CardItemDetailsModal
        {...getProps<CardItemDetailsProps>(ModalKey.CARD_ITEM_DETAILS)}
      />
    </>
  );
};

export default React.memo(ModalsController);
