import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';

import {
  BillableCenterCostsForAdminParams,
  CreateBillableCenterCostsPayload,
  GetBillableCenterCostResponse,
  GetBillableCenterCostsForAdminResponse,
  GetBillableCenterCostsResponse,
  UpdateBillableCenterCostsPayload,
} from './definitions';

export const useBillableCenterCostApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  // Get Billable Center Costs
  const getBillableCenterCosts = useCallback(async () => {
    try {
      const { data } = await privateApi.get<GetBillableCenterCostsResponse>(
        '/billable-center-costs',
      );
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  }, [logError, privateApi]);

  // Get Billable Center Cost
  const getBillableCenterCost = useCallback(
    async (billableCostId: number) => {
      try {
        const { data } = await privateApi.get<GetBillableCenterCostResponse>(
          `/billable-center-costs/${billableCostId}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );
  // Get Billable Center Costs Paginated
  const getBillableCenterCostsForAdmin = useCallback(
    async (params?: BillableCenterCostsForAdminParams) => {
      try {
        const { data } =
          await privateApi.get<GetBillableCenterCostsForAdminResponse>(
            '/billable-center-costs',
            {
              params,
            },
          );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updateBillableCenterCostsStatus = useCallback(
    async ({ id, enabled }: UpdateBillableCenterCostsPayload) => {
      try {
        await privateApi.patch(`/billable-center-costs/${id}/toggle`, {
          enabled,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createBillableCenterCosts = useCallback(
    async (params: CreateBillableCenterCostsPayload) => {
      try {
        const { data } = await privateApi.post(
          '/billable-center-costs',
          params,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createReasonForRequest = useCallback(
    async (params: CreateBillableCenterCostsPayload) => {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        await privateApi.post('/billable-center-costs', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );
  // Return API functions
  return {
    getBillableCenterCosts,
    getBillableCenterCostsForAdmin,
    updateBillableCenterCostsStatus,
    createBillableCenterCosts,
    createReasonForRequest,
    getBillableCenterCost,
  };
};
