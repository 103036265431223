import { useCallback } from 'react';

import usePrivateApi from 'src/api/base/private';

import { useLogger } from '@hooks/useLogger';

import {
  CreateReasonForRequestPayload,
  GetReasonForRequestResponse,
  GetReasonForRequestsParams,
  GetReasonForRequestsResponse,
  UpdateReasonForRequestPayload,
} from './definitions';

export const useReasonForRequestApi = () => {
  const { logError } = useLogger();

  const privateApi = usePrivateApi();

  // Get Reason For Requests
  const getReasonForRequests = useCallback(
    async (params?: GetReasonForRequestsParams) => {
      try {
        const { data } = await privateApi.get<GetReasonForRequestsResponse>(
          '/reason-for-requests',
          { params },
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Get Reason For Request
  const getReasonForRequest = useCallback(
    async (reasonForRequestId: number) => {
      try {
        const { data } = await privateApi.get<GetReasonForRequestResponse>(
          `/reason-for-requests/${reasonForRequestId}`,
        );
        return data;
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const createReasonForRequest = useCallback(
    async (params: CreateReasonForRequestPayload) => {
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((label, index) => {
            formData.append(
              `labels[${index}][languageId]`,
              label.languageId.toString(),
            );
            formData.append(`labels[${index}][label]`, label.label);
          });
        } else {
          formData.append(key, value);
        }
      });

      try {
        await privateApi.post('/reason-for-requests', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  const updateReasonForRequestStatus = useCallback(
    async ({ id, enabled }: UpdateReasonForRequestPayload) => {
      try {
        await privateApi.patch(`/reason-for-requests/${id}/toggle`, {
          enabled,
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    },
    [logError, privateApi],
  );

  // Return API functions
  return {
    getReasonForRequests,
    updateReasonForRequestStatus,
    createReasonForRequest,
    getReasonForRequest,
  };
};
