import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { useShipmentApi } from '@api/ShipmentApi';
import { Card } from '@atoms/Card';
import { IconSVG } from '@atoms/IconSVG';
import { Spinner } from '@atoms/Spinner';
import { Typography } from '@atoms/Typography';
import { useModalsContext } from '@contexts/modals';
import { useUserContext } from '@contexts/user';
import {
  ShipmentStatus,
  StatusHistory,
  shipmentStatusList,
} from '@definitions/Shipment';
import { useDisablePdfDownload } from '@hooks/useDisablePdfDownload';
import { useDownloadShipmentPdf } from '@hooks/useDownload';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import { useShipmentData } from '@hooks/useShipmentData';
import { ActivityCardProps } from '@molecules/ActivityCard/definitions';
import { Select } from '@molecules/Form/Select';
import { useMutation } from '@tanstack/react-query';

const formatStatusLabel = (status: string) =>
  status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

const ActivityCardMobile = ({
  shipment,
  canChangeStatus,
  renderFooter,
}: ActivityCardProps) => {
  const { t } = useTranslation();
  const { invalidateAllShipmentQueries } = useInvalidateQueries();
  const { user, isDriver } = useUserContext();

  const {
    id,
    code,
    status,
    ownerOrganization,
    isUrgent,
    createdAt,
    statusHistory,
    desiredDeliveryDate,
  } = shipment;

  const acceptedWithReserveCount = useMemo(
    () =>
      shipment?.statusHistory.filter(
        (el: StatusHistory) => el.withReserve === true,
      ).length,
    [shipment?.statusHistory],
  );

  const { getShipmentType, getShipmentTotalProducts, getShipmentProductStyle } =
    useShipmentData();

  const shipmentType = getShipmentType(shipment, user);
  const shipmentTotalItems = getShipmentTotalProducts(shipment);
  const entryDate = createdAt
    ? dayjs(createdAt).format('DD/MM/YYYY')
    : undefined;
  const deliveryReq = desiredDeliveryDate
    ? dayjs(desiredDeliveryDate).format('DD/MM/YYYY')
    : undefined;

  const icon = useMemo(
    () => (shipmentType === 'incoming' ? 'download-box' : 'upload-box'),
    [shipmentType],
  );
  const shouldDataSpan = !isUrgent && !shipmentTotalItems;

  const shipmentCurrentStatus =
    statusHistory.length > 1
      ? statusHistory[statusHistory.length - 1]
      : statusHistory[0];
  const { styleId, styleIdType } = getShipmentProductStyle(shipment);

  const [selectValue, setSelectValue] = useState<string>(
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase(),
  );
  const {
    showConfirmationModal,
    showShipmentDetailsModal,
    showShipmentAttachCommentModal,
  } = useModalsContext();

  const isPdfDownloadDisabledStatus = useDisablePdfDownload(shipment?.status);

  const { updateShipmentStatus } = useShipmentApi();
  const { mutate: callUpdateShipmentStatus } = useMutation({
    mutationFn: updateShipmentStatus,
  });

  const onStatusChangeHandler = useCallback(
    (newValue: string) => {
      showConfirmationModal({
        title: t('InCourse.ConfirmationModal.title'),
        content: t('InCourse.ConfirmationModal.content'),
        onConfirm: () => {
          callUpdateShipmentStatus(
            {
              shipmentID: id,
              status: newValue.toLowerCase() as ShipmentStatus,
            },
            {
              onSuccess: () => {
                invalidateAllShipmentQueries();
              },
            },
          );
          setSelectValue(newValue);
        },
      });
    },
    [
      callUpdateShipmentStatus,
      id,
      invalidateAllShipmentQueries,
      showConfirmationModal,
      t,
    ],
  );

  // Comments
  const isCommentDisabled = useMemo(() => {
    if (isDriver) {
      return true;
    }

    return (
      status !== 'arrived' ||
      user?.organization.id !== shipment.receiverOrganizationId
    );
  }, [
    isDriver,
    shipment.receiverOrganizationId,
    status,
    user?.organization.id,
  ]);

  // Pdf
  const isPdfDownloadDisabled = useMemo(
    () => isPdfDownloadDisabledStatus || isDriver,
    [isDriver, isPdfDownloadDisabledStatus],
  );

  const { downloadShipmentPdf, isLoading: isDownloadingShipmentPdf } =
    useDownloadShipmentPdf();

  return (
    <Card backgroundColor="bg-[#F8F8F8]" className="py-2">
      <div className="flex w-full items-center">
        <div className="flex w-full items-center">
          <IconSVG icon={icon} size={24} className="my-2 mr-3" />
          <div className="px-2 border-l border-Primary-02 flex items-start flex-col ">
            <Typography isUppercase isBold size="sm" sizeMd="xl">
              {t('Home.ActivityCard.ID')}: {code}
            </Typography>
            {acceptedWithReserveCount > 0 && (
              <div className="flex items-center">
                <IconSVG icon="shield-warning" size={16} />
                <Typography color="text-Primary-03" size="xs" className=" ml-1">
                  {t('Home.ActivityCard.acceptedWithReserve')}
                </Typography>
              </div>
            )}
          </div>
        </div>
        {canChangeStatus ? (
          <Select
            value={selectValue}
            onChange={(newValue) => onStatusChangeHandler(newValue)}
            options={shipmentStatusList.map((status) => ({
              value: formatStatusLabel(status),
              label: formatStatusLabel(status === 'in_transit' ? 'In transit' : status),
            }))}
          />
        ) : (
          <Typography isBold size="sm" className="md:mr-8 capitalize">
            {status === 'in_transit' ? t('General.ShipmentStatus.inTransit') : status}
          </Typography>
        )}
      </div>
      <div className="w-full flex gap-23 items-center">
        <div className="w-full">
          <div className="mt-3">
            <div className="w-full flex gap-2 flex-col">
              <div className="grid grid-cols-3 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.address')}
                </Typography>
                <div className="col-span-2">
                  <Typography className="overflow-hidden text-ellipsis ">
                    {ownerOrganization.address}
                  </Typography>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.owner')}
                </Typography>
                <div className="col-span-2">
                  <Typography className="overflow-hidden text-ellipsis ">
                    {ownerOrganization.name}
                  </Typography>
                </div>
              </div>
              {styleIdType && (
                <div className="grid grid-cols-3 gap-4">
                  <Typography color="text-Primary-02">
                    {t(`General.${styleIdType}`)}
                  </Typography>
                  <div className={`${shouldDataSpan ? 'col-span-2' : ''}`}>
                    <Typography className="truncate overflow-hidden text-ellipsis">
                      {styleId}
                    </Typography>
                  </div>
                  {shipmentTotalItems && (
                    <div className="text-center col-start-3 text-sm text-Primary-03">
                      {shipmentTotalItems}
                    </div>
                  )}
                </div>
              )}
              <div className="grid grid-cols-3 gap-4">
                <Typography color="text-Primary-02">
                  {t('Home.ActivityCard.entryDate')}
                </Typography>
                <div className={`${shouldDataSpan ? 'col-span-2' : ''}`}>
                  <Typography className="overflow-hidden text-ellipsis ">
                    {entryDate}
                  </Typography>
                </div>
                {isUrgent && (
                  <div className="text-center col-start-3">
                    <div className="p-[2px] w-fit flex justify-center bg-Urgent-red">
                      <Typography isUppercase color="text-Primary-05" size="sm">
                        {t('Home.ActivityCard.urgent')}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
              {deliveryReq && (
                <div className="grid grid-cols-3 gap-4">
                  <Typography color="text-Primary-02">
                    {t('Home.ActivityCard.deliveryReq')}
                  </Typography>
                  <div className="col-span-2">
                    <Typography className="overflow-hidden text-ellipsis ">
                      {deliveryReq}
                    </Typography>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full h-fit col-span-1 mt-2">
              <div
                className={
                  "flex w-full items-center bg-custom bg-[url('/public/delivery.svg')] bg-no-repeat pl-10 bg-full"
                }
              >
                {shipmentCurrentStatus && (
                  <div className="mt-1 w-full flex flex-col items-start">
                    <Typography isBold size="sm" className="capitalize">
                      {shipmentCurrentStatus.status}
                    </Typography>
                    <div className="grid w-full grid-cols-2 justify-items-start items-start">
                      <Typography size="sm">
                        {`${shipmentCurrentStatus.user.firstName} ${shipmentCurrentStatus.user.lastName}, ${shipmentCurrentStatus.user.organization.name}`}
                      </Typography>
                      {shipmentCurrentStatus.withReserve && (
                        <IconSVG icon="shield-warning" size={16} />
                      )}
                    </div>
                    <Typography size="sm">
                      {dayjs(shipmentCurrentStatus.setAt).format(
                        'DD/MM/YYYY at HH:mm',
                      )}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 flex w-full items-center">
        <div className="w-full">
          <button
            onClick={() => {
              showShipmentDetailsModal({
                shipment,
              });
            }}
            className="flex items-center w-full"
          >
            <Typography className="mr-1">
              {t('Home.ActivityCard.viewDetails')}
            </Typography>
            <IconSVG icon="chevron-right" size={12} />
          </button>
        </div>
        <div className=" w-full flex justify-end gap-7">
          <IconSVG
            icon={shipment.delayedComment ? 'tooltip-notification' : 'tooltip'}
            size={16}
            onClick={() =>
              showShipmentAttachCommentModal({
                shipmentId: shipment.id,
                delayedComment: shipment.delayedComment,
              })
            }
            disabled={isCommentDisabled}
          />
          {isDownloadingShipmentPdf ? (
            <Spinner size="sm" className="inline-flex" />
          ) : (
            <IconSVG
              icon="download"
              size={18}
              onClick={() => downloadShipmentPdf(shipment.id)}
              disabled={isPdfDownloadDisabled}
            />
          )}
        </div>
      </div>
      {renderFooter?.(shipment)}
    </Card>
  );
};

export default React.memo(ActivityCardMobile);
