import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ShipmentFlow } from '@contexts/shipment/definitions';
import { FinalStatus, ReceiverFunction } from '@organisms/Modals/ReceiveFlow/ReceiveShipmentDetails/definitions';

import {
  ReceiveContextProps,
  ReceiveProviderProps,
  ReceivedShipment,
} from './definitions';

const undefinedMethod = () => {
  throw new Error('[ReceiveContext] Context not initialised');
};

const ReceiveContext = createContext<ReceiveContextProps>({
  selectedShipmentId: undefined,
  setSelectedShipmentId: undefinedMethod,
  selectedShipmentCode: undefined,
  setSelectedShipmentCode: undefinedMethod,
  receiverFunction: undefined,
  setReceiverFunction: undefinedMethod,
  receivedShipment: undefined,
  isFinalUser: false,
  setReceivedShipment: undefinedMethod,
  reset: undefinedMethod,
  flow: undefined,
  setFlow: undefinedMethod,
  finalStatus: undefined,
});

export const ReceiveProvider = ({ children }: ReceiveProviderProps) => {
  const [selectedShipmentId, setSelectedShipmentId] = useState<number>();
  const [selectedShipmentCode, setSelectedShipmentCode] = useState<string>();
  const [receiverFunction, _setReceiverFunction] = useState<
    ReceiverFunction | undefined
  >();
  const [isFinalUser, _setIsFinalUser] = useState<boolean>(false);
  const [receivedShipment, _setReceivedShipment] = useState<ReceivedShipment>();
  const [flow, setFlow] = useState<ShipmentFlow>();
  const [finalStatus, _setFinalStatus] = useState<FinalStatus>();

  /**
   * Update the shipment state with the specified field, keeping the other fields unchanged.
   * @param field The field to update.
   * @param value The new value for the field.
   */
  const setReceivedShipment = useCallback(
    <K extends keyof ReceivedShipment>(
      field: K,
      value: ReceivedShipment[K],
    ) => {
      _setReceivedShipment((prevShipmentState) => {
        if (!prevShipmentState) {
          return { [field]: value } as unknown as ReceivedShipment;
        }
        return { ...prevShipmentState, [field]: value };
      });
    },
    [],
  );

  const setReceiverFunction = useCallback((role: ReceiverFunction) => {
    _setReceiverFunction(role);
    _setIsFinalUser(role === ReceiverFunction.FINAL_ADDRESSEE);
    
    switch (role) {
      case ReceiverFunction.FINAL_ADDRESSEE:
        _setFinalStatus('delivered');
        break;
      case ReceiverFunction.RECEPTION:
        _setFinalStatus('arrived');
        break;
      case ReceiverFunction.INTERMEDIATE:
        _setFinalStatus('in_transit');
        break;
    }
  }, []);

  const reset = useCallback(() => {
    setSelectedShipmentId(undefined);
    _setReceivedShipment(undefined);
    setSelectedShipmentCode(undefined);
    _setReceiverFunction(undefined);
    _setIsFinalUser(false);
  }, []);

  const memoizedValue = useMemo<ReceiveContextProps>(
    () => ({
      selectedShipmentId,
      setSelectedShipmentId,
      selectedShipmentCode,
      setSelectedShipmentCode,
      receiverFunction,
      setReceiverFunction,
      reset,
      receivedShipment,
      isFinalUser,
      setReceivedShipment,
      flow,
      setFlow,
      finalStatus,
    }),
    [
      selectedShipmentId,
      selectedShipmentCode,
      receiverFunction,
      setReceiverFunction,
      reset,
      receivedShipment,
      isFinalUser,
      setReceivedShipment,
      flow,
      finalStatus,
    ],
  );

  return (
    <ReceiveContext.Provider value={memoizedValue}>
      {children}
    </ReceiveContext.Provider>
  );
};

export const useReceiveContext = (): ReceiveContextProps =>
  useContext(ReceiveContext);
