export interface ReceiveShipmentDetailsModalProps {
  open: boolean;
  onClose: () => void;
}

export enum ReceiverFunction {
  FINAL_ADDRESSEE = 'final_addressee',
  INTERMEDIATE = 'intermediate',
  RECEPTION = 'reception',
}

export type FinalStatus = 'in_transit' | 'arrived' | 'delivered';
