import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { GetPackagingsResponse } from '@api/packagingApi/definitions';
import { useReasonForRequestApi } from '@api/reasonForRequestApi';
import {
  GetReasonForRequestsParams,
  ReasonForRequestsItem,
} from '@api/reasonForRequestApi/definitions';
import { useModalsContext } from '@contexts/modals';
import { QueryKeys } from '@definitions/QueryKeys';
import useToast from '@hooks/useToast';
import { Toggle } from '@molecules/Form/Toggle';
import { useTableState } from '@molecules/Table/tableState';
import { AdminTableCard } from '@organisms/AdminTableCard';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';

const SEARCH_MINIMUM_CHARACTERS = 3;

const ReasonForRequest = () => {
  const { t } = useTranslation();

  const { showWarningToast } = useToast();

  const { showConfirmationModal, showAddGenericItemModal } = useModalsContext();

  const queryClient = useQueryClient();

  const {
    getReasonForRequests,
    updateReasonForRequestStatus,
    createReasonForRequest,
  } = useReasonForRequestApi();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters =
      apiSearchValue.length >= SEARCH_MINIMUM_CHARACTERS;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const tableState = useTableState({
    defaultSorting: [
      { id: 'enabled', desc: true },
      { id: 'label', desc: true },
    ],
  });

  const queryParams = useMemo<GetReasonForRequestsParams>(() => {
    const { sorting, columnFilters } = tableState;

    const params: GetReasonForRequestsParams = {};

    if (sorting.length > 0) {
      params.orderBy = sorting.map(({ id, desc }) => ({
        columnName: id,
        sortDirection: desc ? 'desc' : 'asc',
      }));
    }

    if (columnFilters.length > 0) {
      const nameFilter = columnFilters.find((f) => f.id === 'name');
      if (nameFilter) {
        params.textFilter = nameFilter.value as string;
      }
    }

    return params;
  }, [tableState]);

  const { data: reasonData, isLoading } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_REASON_FOR_REQUESTS, queryParams],
    queryFn: () => getReasonForRequests(queryParams),
    placeholderData: keepPreviousData,
  });

  const { mutate: callUpdateReasonForRequests } = useMutation({
    mutationFn: updateReasonForRequestStatus,
    onMutate: async ({ id, enabled }) => {
      const queryKey = [QueryKeys.SHIPMENT_REASON_FOR_REQUESTS, queryParams];

      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousData =
        queryClient.getQueryData<GetPackagingsResponse>(queryKey);

      if (previousData) {
        queryClient.setQueryData<GetPackagingsResponse>(
          queryKey,
          previousData.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                enabled,
              };
            }
            return item;
          }),
        );
      }

      return { previousData };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData<GetPackagingsResponse>(
          [QueryKeys.SHIPMENT_REASON_FOR_REQUESTS, queryParams],
          context.previousData,
        );
      }

      showWarningToast(
        t('Modals.ReasonForRequestStatusConfirmation.errorMessage'),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.SHIPMENT_REASON_FOR_REQUESTS, queryParams],
      });
    },
  });

  const tableData = useMemo(() => {
    return reasonData ?? [];
  }, [reasonData]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<ReasonForRequestsItem>();

    return [
      columnHelper.accessor('label', {
        header: t('ItemsPage.reasonForRequest.table.columns.name'),
        enableSorting: true,
      }),
      columnHelper.accessor('enabled', {
        header: t('ItemsPage.reasonForRequest.table.columns.status'),
        enableSorting: true,
        cell: (info) => {
          return (
            <div className="flex flex-row items-center">
              <Toggle
                checked={info.getValue()}
                onChange={(checked) =>
                  showConfirmationModal({
                    title: t('Modals.ReasonForRequestStatusConfirmation.title'),
                    content: t(
                      'Modals.ReasonForRequestStatusConfirmation.content',
                    ),
                    onConfirm: () => {
                      callUpdateReasonForRequests({
                        id: Number(info.row.original.id),
                        enabled: checked,
                      });
                    },
                  })
                }
              />
            </div>
          );
        },
      }),
    ];
  }, [t, showConfirmationModal, callUpdateReasonForRequests]);

  useEffect(() => {
    const { onColumnFiltersChange } = tableState;

    if (parsedApiSearchValue !== '') {
      onColumnFiltersChange((prevState) => {
        // All these checks are important to avoid infinite loop
        const currentFilter = prevState.find((f) => f.id === 'name');

        if (currentFilter === undefined) {
          return [
            ...prevState,
            {
              id: 'name',
              value: parsedApiSearchValue,
            },
          ];
        }

        if (currentFilter.value !== parsedApiSearchValue) {
          return prevState.map((filter) => {
            if (filter.id === 'name') {
              return {
                ...filter,
                value: parsedApiSearchValue,
              };
            }
            return { ...filter };
          });
        }

        return prevState;
      });

      return;
    }

    onColumnFiltersChange((prevState) => {
      // This check is important to avoid infinite loop
      return prevState.length > 0 ? [] : prevState;
    });

    return;
  }, [parsedApiSearchValue, tableState]);

  return (
    <AdminTableCard
      isLoading={isLoading}
      title={t('ItemsPage.reasonForRequest.table.title')}
      search={{
        name: 'search-reasonforrequests',
        value: searchValue,
        onChange: setSearchValue,
      }}
      table={{
        data: tableData,
        columns,
        ...tableState,
        showPagination: false,
      }}
      footerActions={[
        {
          label: t('ItemsPage.reasonForRequest.add'),
          onClick: () =>
            showAddGenericItemModal({
              createItem: createReasonForRequest,
              addItemLabelNs: 'AddReasonForRequest',
              addEditItemLabelNs: 'AddEditReasonForRequest',
              onItemCreated: () => {
                queryClient.invalidateQueries({
                  queryKey: [QueryKeys.SHIPMENT_REASON_FOR_REQUESTS],
                  exact: false,
                });
              },
            }),
        },
      ]}
    />
  );
};

export default React.memo(ReasonForRequest);
