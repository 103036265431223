import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Checkbox } from '@atoms/Checkbox';
import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import useWindow from '@hooks/useWindow';
import {
  DateRangeFilter,
  DateRangeFilterValue,
} from '@molecules/Filters/DateRangeFilter';
import { OrganizationIdFilter } from '@molecules/Filters/OrganizationId';
import { OrganizationTypeFilter } from '@molecules/Filters/OrganizationType';
import { ProductTypeFilter } from '@molecules/Filters/ProductType';
import { ShipmentTypeFilter } from '@molecules/Filters/ShipmentType';

import { FilterKeys, FiltersData, TimechartFiltersProps } from './definitions';

const TIMECHART_INITIAL_FILTERS: FiltersData = {
  type: '',
  id: [],
  organizationType: '',
  organizationId: '',
  dates: '',
  urgent: false,
  deliveryDate: false,
  byReserve: false,
  excludeCancelled: false,
};

const TimechartFilters = ({ onFiltersChange }: TimechartFiltersProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();

  const { isAdmin } = useUserContext();

  const [filters, setFilters] = useState<FiltersData>(
    TIMECHART_INITIAL_FILTERS,
  );

  const updateFilters = useCallback(
    (key: FilterKeys, value: string | string[] | boolean) => {
      setFilters((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const handleApply = useCallback(() => {
    onFiltersChange(filters);
  }, [filters, onFiltersChange]);

  const handleReset = useCallback(() => {
    setFilters(TIMECHART_INITIAL_FILTERS);
    onFiltersChange(null);
  }, [onFiltersChange]);

  const isButtonDisabled = useMemo(() => {
    return (
      !filters.type &&
      filters.id.length === 0 &&
      !filters.dates &&
      !filters.organizationType &&
      !filters.organizationId
    );
  }, [filters]);

  const ShipmentType = useMemo(() => {
    return (
      <div className={`${isMobile ? 'w-full' : 'flex-1'}`}>
        <ShipmentTypeFilter
          value={filters.type}
          onChange={(value) => updateFilters('type', value)}
        />
      </div>
    );
  }, [filters.type, isMobile, updateFilters]);

  const ProductType = useMemo(() => {
    return (
      <div className={`${isMobile ? 'w-full' : 'flex-1'}`}>
        <ProductTypeFilter
          value={filters.id}
          onChange={(value) => updateFilters('id', value)}
        />
      </div>
    );
  }, [filters.id, isMobile, updateFilters]);

  const OrganizationType = useMemo(() => {
    return (
      <div className="flex-1">
        <OrganizationTypeFilter
          value={filters.organizationType}
          onChange={(value) => updateFilters('organizationType', value)}
        />
      </div>
    );
  }, [filters, updateFilters]);

  const OrganizationId = useMemo(() => {
    return (
      <div className="flex-1">
        <OrganizationIdFilter
          value={filters.organizationId}
          onChange={(value) => updateFilters('organizationId', value)}
        />
      </div>
    );
  }, [filters, updateFilters]);

  const DateRange = useMemo(() => {
    const dateRangeFilterValue = (
      filters.dates ? filters.dates.split(';') : [null, null]
    ) as DateRangeFilterValue;

    const handleDateRangeFilterChange = (value: DateRangeFilterValue) => {
      const parsedValue = value.filter(Boolean);
      updateFilters(
        'dates',
        parsedValue.length > 0 ? parsedValue.join(';') : '',
      );
    };

    return (
      <div className="flex-1">
        <DateRangeFilter
          value={dateRangeFilterValue}
          onChange={handleDateRangeFilterChange}
        />
      </div>
    );
  }, [filters, updateFilters]);

  const Urgent = useMemo(() => {
    return (
      <div className="flex-1 flex flex-row items-center justify-center">
        <label className="flex gap-2 items-center cursor-pointer">
          <Checkbox
            name="urgent"
            value="urgent"
            checked={filters.urgent}
            onChange={(_, checked) => updateFilters('urgent', checked)}
            aria-labelledby={t('Filters.urgent.label')}
            size="xs"
          />
          <Typography size="md" color="text-Primary-02">
            {t('Filters.urgent.label')}
          </Typography>
        </label>
      </div>
    );
  }, [filters, t, updateFilters]);

  const DeliveryDate = useMemo(() => {
    return (
      <div className="flex-1 flex flex-row items-center justify-center">
        <label className="flex gap-2 items-center cursor-pointer">
          <Checkbox
            name="deliveryDate"
            value="deliveryDate"
            checked={filters.deliveryDate}
            onChange={(_, checked) => updateFilters('deliveryDate', checked)}
            aria-labelledby={t('Filters.deliveryDate.label')}
            size="xs"
          />
          <Typography size="md" color="text-Primary-02">
            {t('Filters.deliveryDate.label')}
          </Typography>
        </label>
      </div>
    );
  }, [filters, t, updateFilters]);

  const ByReserve = useMemo(() => {
    return (
      <div className="flex-1 flex flex-row items-center justify-center">
        <label className="flex gap-2 items-center cursor-pointer">
          <Checkbox
            name="byReserve"
            value="byReserve"
            checked={filters.byReserve}
            onChange={(_, checked) => updateFilters('byReserve', checked)}
            aria-labelledby={t('Filters.byReserve.label')}
            size="xs"
          />
          <Typography size="md" color="text-Primary-02">
            {t('Filters.byReserve.label')}
          </Typography>
        </label>
      </div>
    );
  }, [filters, t, updateFilters]);

  const ExcludeCancelled = useMemo(() => {
    return (
      <div className="flex-1 flex flex-row items-center justify-center">
        <label className="flex gap-2 items-center cursor-pointer">
          <Checkbox
            name="excludeCancelled"
            value="excludeCancelled"
            checked={filters.excludeCancelled}
            onChange={(_, checked) => updateFilters('excludeCancelled', checked)}
            aria-labelledby={t('Filters.excludeCancelled.label')}
            size="xs"
          />
          <Typography size="md" color="text-Primary-02">
            {t('Filters.excludeCancelled.label')}
          </Typography>
        </label>
      </div>
    );
  }, [filters, t, updateFilters]);

  const FiltersSecondRowNode = useMemo(() => {
    return isAdmin ? (
      <Fragment>
        {DateRange}
        {Urgent}
        {DeliveryDate}
        {ByReserve}
        {ExcludeCancelled}
      </Fragment>
    ) : (
      <Fragment>
        {Urgent}
        {DeliveryDate}
        {ByReserve}
      </Fragment>
    );
  }, [ByReserve, DateRange, DeliveryDate, ExcludeCancelled, Urgent, isAdmin]);

  const FiltersFirstRowNode = useMemo(() => {
    return isAdmin ? (
      <Fragment>
        {ShipmentType}
        {ProductType}
        {OrganizationType}
        {OrganizationId}
      </Fragment>
    ) : (
      <Fragment>
        {ShipmentType}
        {ProductType}
        {DateRange}
      </Fragment>
    );
  }, [
    DateRange,
    OrganizationId,
    OrganizationType,
    ProductType,
    ShipmentType,
    isAdmin,
  ]);

  const ButtonsNode = useMemo(() => {
    return (
      <div
        className={`flex ${isAdmin ? 'flex-col' : 'flex-row-reverse'} gap-2 ${isMobile ? 'flex-col w-full' : ''}`}
      >
        <Button
          type="primary"
          label={t('General.apply')}
          onClick={handleApply}
          disabled={isButtonDisabled}
        />
        <Button
          type="secondary"
          label={t('General.clearAll')}
          onClick={handleReset}
          disabled={isButtonDisabled}
        />
      </div>
    );
  }, [handleApply, handleReset, isAdmin, isButtonDisabled, isMobile, t]);

  return (
    <div className="w-full flex-col flex gap-6 items-stretch">
      <div
        className={`flex ${isMobile ? 'flex-col gap-3' : 'flex-row gap-6 items-center'}`}
      >
        {FiltersFirstRowNode}
      </div>
      <div
        className={`flex ${isMobile ? 'flex-col gap-4 items-start' : 'flex-row gap-6 items-center'}`}
      >
        <div
          className={`flex-1 flex ${isMobile ? 'flex-col gap-4 items-start' : 'flex-row items-end gap-0'}`}
        >
          {FiltersSecondRowNode}
        </div>
        {ButtonsNode}
      </div>
    </div>
  );
};

export default React.memo(TimechartFilters);
