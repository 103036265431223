import { useCallback, useMemo } from 'react';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { useUserApi } from '@api/UserApi';
import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import { Organization } from '@definitions/Organization';
import { QueryKeys } from '@definitions/QueryKeys';
import { useLogger } from '@hooks/useLogger';
import { Select } from '@molecules/Form/Select';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import organizationImagePlaceholder from '@utils/organizationImagePlaceholder';

const UserDetails = () => {
  const { user } = useUserContext();
  const { logError } = useLogger();
  const queryClient = useQueryClient();

  const { changeOrganization: changeOrganizationApi } = useUserApi();
  const { getOrganizationType } = useOrganizationsApi();

  const { data: organizationType } = useQuery({
    queryKey: [
      QueryKeys.ORGANIZATIONS_TYPE,
      user?.organization.organizationTypeId,
    ],
    queryFn: () =>
      getOrganizationType({ id: user?.organization.organizationTypeId || 0 }),
  });

  const { mutate: callChangeOrganizationApi } = useMutation({
    mutationFn: changeOrganizationApi,
  });

  const hasMultipleOrganizations = useMemo(
    () => user?.organizations && user?.organizations.length > 1,
    [user?.organizations],
  );

  const organizationsOptions = useMemo(() => {
    return user?.organizations?.map((organization: Organization) => {
      return {
        value: `${organization.id}`,
        label: organization.name,
      };
    });
  }, [user?.organizations]);

  const selectedOrganization = useMemo(
    () =>
      organizationsOptions?.find(
        (item) => item.label === user?.organization.name,
      ),
    [organizationsOptions, user?.organization.name],
  );

  const onChange = useCallback(
    (value: string) => {
      if (value === selectedOrganization?.value) {
        return;
      }

      callChangeOrganizationApi(
        {
          organizationId: Number(value),
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
          },
          onError: (error) => {
            logError(error);
          },
        },
      );
    },
    [callChangeOrganizationApi, logError, selectedOrganization?.value],
  );

  return (
    <>
      <div className="flex gap-4">
        <div>
          <div className="flex items-center justify-center rounded-full w-11 h-11">
            <img
              src={
                organizationType?.image
                  ? organizationType.image
                  : organizationImagePlaceholder()
              }
              alt="User image"
              className="rounded-full"
            />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <Typography size="md" isBold>
            {user?.firstName} {user?.lastName}
          </Typography>
        </div>
      </div>
      {hasMultipleOrganizations ? (
        <div className="flex gap-4">
          <Select
            className="w-full"
            variant="default"
            value={selectedOrganization!.value}
            onChange={onChange}
            options={organizationsOptions || []}
          />
        </div>
      ) : (
        <div className="flex gap-4 border-Primary-00 border-b pb-2">
          <Typography size="md" color="text-Primary-03">
            {user?.organization?.name}
          </Typography>
        </div>
      )}
    </>
  );
};
export default UserDetails;
