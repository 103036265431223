import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { Typography } from '@atoms/Typography';
import { QueryKeys } from '@definitions/QueryKeys';
import { Select } from '@molecules/Form/Select';
import { useInfiniteQuery } from '@tanstack/react-query';
import { noop } from '@utils/noop';

import { OrganizationsSectionProps } from './definitions';

const SEARCH_MINIMUM_CHARACTERS = 3;

const OrganizationsSection = ({
  isRequiredAt,
  organizationId,
  onChangeOrganizationId,
}: OrganizationsSectionProps) => {
  const { t } = useTranslation();
  const { getOrganizationsRecipients } = useOrganizationsApi();

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const hasMinimumCharacters =
    apiSearchValue.length >= SEARCH_MINIMUM_CHARACTERS;

  const parsedApiSearchValue = useMemo(() => {
    return hasMinimumCharacters ? apiSearchValue : '';
  }, [apiSearchValue, hasMinimumCharacters]);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [
      QueryKeys.SHIPMENT_ORGANIZATIONS_RECIPIENTS,
      parsedApiSearchValue,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationsRecipients({
        page: pageParam,
        perPage: 20,
        textFilter: parsedApiSearchValue || undefined,
      }),

    initialPageParam: 1,
    getNextPageParam: (thisPage) =>
      thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
        ? null
        : thisPage.meta.currentPage + 1,
  });

  const organizationsSelectOptions = useMemo(() => {
    return (
      data?.pages.flatMap((pages) =>
        pages.data.map((org) => ({
          id: org.id,
          name: org.name,
          address: org.address,
        })),
      ) || []
    );
  }, [data?.pages]);

  const selectedOrganization = useMemo(() => {
    const matchingOrganization = organizationsSelectOptions.find(
      (org) => org.id === organizationId,
    );

    if (matchingOrganization) {
      return {
        name: matchingOrganization.name,
        address: matchingOrganization.address,
      };
    }
  }, [organizationId, organizationsSelectOptions]);

  const handleSelectOrganization = useCallback(
    (newSelectedOrganizationName: string) => {
      onChangeOrganizationId(
        organizationsSelectOptions.find(
          (org) => org.name === newSelectedOrganizationName,
        )?.id,
      );
    },
    [onChangeOrganizationId, organizationsSelectOptions],
  );

  const selectOptions =
    useMemo(() => {
      return organizationsSelectOptions.map((org) => ({
        label: org.name,
        value: org.name,
      }));
    }, [organizationsSelectOptions]) ?? [];

  const selectValue =
    useMemo(() => {
      return selectedOrganization?.name || '';
    }, [selectedOrganization?.name]) ?? '';

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <Typography size="sm" sizeMd="md" color="text-Primary-02">
          {t(
            `Modals.GenericShipmentResume.${isRequiredAt ? 'requiredAt' : 'consignee'}`,
          )}
          :
        </Typography>
        <Select
          className="w-full"
          variant="search"
          value={selectValue}
          onChange={handleSelectOrganization}
          placeholder={t('Filters.organizationId.placeholder')}
          options={selectOptions}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          onBottomReached={hasNextPage ? fetchNextPage : noop}
        />
      </div>

      <div className="flex items-center gap-2">
        <Typography size="sm" sizeMd="md" color="text-Primary-02">
          {t('Modals.GenericShipmentResume.address')}:
        </Typography>
        <Typography size="sm" sizeMd="md">
          {selectedOrganization?.address}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(OrganizationsSection);
