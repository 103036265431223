import React, { useMemo } from 'react';

import {
  GetShipmentStatisticsParams,
  ShipmentFlow,
  ShipmentStyleIdType,
  useStatisticsApi,
} from '@api/statistics';
import { QueryKeys } from '@definitions/QueryKeys';
import { useQuery } from '@tanstack/react-query';

import { AverageDeliveryTime } from './AverageDeliveryTime';
import { TransferAndRequest } from './TransferAndRequest';
import { TransferPlusRequest } from './TransferPlusRequest';
import {
  AverageDeliveryTimeProps,
  TimechartProps,
  TransferAndRequestProps,
  TransferPlusRequestProps,
} from './definitions';
import {
  getAverageDeliveryTimeChartData,
  getTransferAndRequestChartData,
  getTransferPlusRequestChartData,
} from './utils';

const Timechart = ({ filters }: TimechartProps) => {
  const { getShipmentStatistcis } = useStatisticsApi();

  const queryParams = useMemo<GetShipmentStatisticsParams>(() => {
    const params: GetShipmentStatisticsParams = {};

    if (filters?.type) {
      params.flow = filters.type as ShipmentFlow;
    }

    if (filters?.id) {
      params.styleIdTypes = filters.id as ShipmentStyleIdType[];
    }

    if (filters?.organizationType) {
      params.organizationTypeId = Number(filters.organizationType);
    }

    if (filters?.organizationId) {
      params.organizationId = Number(filters.organizationId);
    }

    if (filters?.dates) {
      const [from, to] = filters.dates.split(';');
      params.from = from;
      params.to = to;
    }

    if (filters?.urgent) {
      params.isUrgent = filters.urgent;
    }

    if (filters?.deliveryDate) {
      params.hasDesiredDeliveryDate = filters.deliveryDate;
    }

    if (filters?.byReserve) {
      params.withReserve = filters.byReserve;
    }

    if (filters?.excludeCancelled) {
      params.excludeCancelled = filters.excludeCancelled;
    }

    return params;
  }, [filters]);

  const { data: shipmentStatisticsData } = useQuery({
    queryKey: [QueryKeys.SHIPMENT_STATISTICS, queryParams],
    queryFn: () => getShipmentStatistcis(queryParams),
  });

  const transferPlusRequestChartData = useMemo<
    TransferPlusRequestProps['chartData']
  >(() => {
    return getTransferPlusRequestChartData(shipmentStatisticsData);
  }, [shipmentStatisticsData]);

  const transferAndRequestChartData = useMemo<
    TransferAndRequestProps['chartData']
  >(() => {
    return getTransferAndRequestChartData(shipmentStatisticsData);
  }, [shipmentStatisticsData]);

  const averageDeliveryTimeChartData = useMemo<
    AverageDeliveryTimeProps['chartData']
  >(() => {
    return getAverageDeliveryTimeChartData(shipmentStatisticsData);
  }, [shipmentStatisticsData]);

  return (
    <div className="w-full flex flex-col items-stretch gap-4">
      <TransferPlusRequest chartData={transferPlusRequestChartData} />
      <TransferAndRequest chartData={transferAndRequestChartData} />
      <AverageDeliveryTime chartData={averageDeliveryTimeChartData} />
    </div>
  );
};

export default React.memo(Timechart);
