export enum QueryKeys {
  LOGIN_PASSWORD_POLICY = 'auth_password_policy',
  USERS_ME = 'users_me',
  USER_LIST = 'user_list',
  ORGANIZATIONS_TYPE_LIST = 'organizations_type_list',
  ORGANIZATIONS_TYPE = 'organizations_type',
  ORGANIZATION_LIST = 'organization_list',
  ORGANIZATION = 'organization',
  ORGANIZATIONS_STATISTICS = 'organizations_statistics',
  SHIPMENT_STATISTICS = 'shipment_statistics',
  ROLE_LIST = 'role_list',
  SHIPMENT_ORGANIZATIONS_RECIPIENTS = 'shipment_organizations_recipients',

  PRODUCTS = 'products',
  PRODUCTS_STOCK_COUNT = 'products_stock_count',
  PRODUCT_CATEGORIES = 'product_categories',
  PRODUCT_CATEGORY = 'product_category',

  SHIPMENT = 'shipment',
  SHIPMENTS = 'shipments',
  SHIPMENT_PRODUCTS = 'shipment_products',
  SHIPMENT_PRODUCTS_SEARCH = 'shipment_products_search',
  SHIPMENT_SUPPORTS = 'shipment_supports',
  SHIPMENT_BILLABLE_CENTER_COST = 'shipment_billable_center_cost',
  SHIPMENT_BILLABLE_CENTER_COSTS = 'shipment_billable_center_costs',
  SHIPMENT_BILLABLE_CENTER_COSTS_FOR_ADMIN = 'shipment_billable_center_costs_for_admin',
  SHIPMENT_REASON_FOR_REQUESTS = 'shipment_reason_for_requests',
  SHIPMENT_REASON_FOR_REQUEST = 'shipment_reason_for_request',
  SHIPMENT_PACKAGINGS = 'shipment_packagings',
  SHIPMENT_IN_COURSE = 'shipment_in_course',
  SHIPMENTS_IN_COURSE_FILTER = 'shipments_in_course_filter',
  SHIPMENT_HISTORY = 'shipment_history',
  SHIPMENTS_HISTORY_FILTER = 'shipments_history_filter',
  SHIPMENTS_PENDING = 'shipments_pending',
  SHIPMENTS_HOME = 'shipments_home',
  SHIPMENT_PDF = 'shipment_pdf',
  NOTIFICATION_SETTINGS = 'notification_settings',

  LANGUAGES = 'languages',
}
