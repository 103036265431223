import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  AddGenericItemProps,
  CardItemDetailsProps,
  ConfirmationProps,
  EditGenericItemProps,
  ModalEntity,
  ModalKey,
  ModalOptions,
  ModalsContextProps,
  ModalsProviderProps,
  ReceiveShipmentIdProps,
  RequestCheckingProps,
  ResumeConfirmationProps,
  ShipmentAddItemCodeProps,
  ShipmentAttachCommentProps,
  ShipmentDetailsProps,
  ShipmentImagePreviewProps,
  ShipmentItemDetailsProps,
  ShowModal,
} from './definitions';

const undefinedMethod = () => {
  throw new Error('[ModalsContext] Context not initialised');
};

const ModalsContext = createContext<ModalsContextProps>({
  modals: [],
  showModal: undefinedMethod,
  hideModal: undefinedMethod,
  showChangePasswordModal: undefinedMethod,
  showShipmentSelectAddressModal: undefinedMethod,
  showShipmentSelectProductsModal: undefinedMethod,
  hideAllShipmentModals: undefinedMethod,
  hideAllReceiveModals: undefinedMethod,
  showConfirmationModal: undefinedMethod,
  showResumeConfirmationModal: undefinedMethod,
  showShipmentAddItemCodeModal: undefinedMethod,
  showShipmentItemDetailsModal: undefinedMethod,
  showShipmentSelectPackagingModal: undefinedMethod,
  showShipmentRemarksModal: undefinedMethod,
  showReceiveRemarksModal: undefinedMethod,
  showShipmentImagePreviewModal: undefinedMethod,
  showShipmentResumeModal: undefinedMethod,
  showAddOrganizationModal: undefinedMethod,
  showAddUserModal: undefinedMethod,
  showAddGenericItemModal: undefinedMethod,
  showEditGenericItemModal: undefinedMethod,
  showShipmentDetailsModal: undefinedMethod,
  showNotificationModal: undefinedMethod,
  showPendingShipmentRequestApprovalModal: undefinedMethod,
  showPendingShipmentSelectProductsModal: undefinedMethod,
  showPendingShipmentSelectPackagingModal: undefinedMethod,
  showPendingShipmentResumeModal: undefinedMethod,
  hideAllPendingShipmentModals: undefinedMethod,
  showReceiveSelectCodeModal: undefinedMethod,
  showReceiveShipmentIdModal: undefinedMethod,
  showReceiveShipmentDetailsModal: undefinedMethod,
  showShipmentAttachCommentModal: undefinedMethod,
  showRequestCheckingModal: undefinedMethod,
  showCardItemDetailsModal: undefinedMethod,
});

const DEFAULT_OPTIONS: ModalOptions = {
  persistent: false,
};

export const ModalsProvider = ({ children }: ModalsProviderProps) => {
  const [modals, _setModals] = useState<Array<ModalEntity>>([]);

  const _removeModal = useCallback((modalKey: ModalKey) => {
    _setModals((prevModals) => {
      return prevModals.filter(({ key }) => key !== modalKey);
    });
  }, []);

  const _setModal = useCallback((newModal: (typeof modals)[number]) => {
    _setModals((prevModals) => {
      const index = prevModals.findIndex(({ key }) => key === newModal.key);

      if (index === -1) {
        return [...prevModals, newModal];
      }

      prevModals[index] = newModal;

      return prevModals;
    });
  }, []);

  // Public
  const showModal = useCallback<ShowModal>(
    (...args) => {
      const [key] = args;
      const isModalWithCustomProps =
        key === ModalKey.CONFIRMATION ||
        key === ModalKey.RESUME_CONFIRMATION ||
        key === ModalKey.SHIPMENT_ITEM_DETAILS ||
        key === ModalKey.SHIPMENT_ADD_ITEM_CODE ||
        key === ModalKey.SHIPMENT_IMAGE_PREVIEW ||
        key === ModalKey.SHIPMENT_ATTACH_COMMENT ||
        key === ModalKey.SHIPMENT_DETAILS ||
        key === ModalKey.RECEIVE_SHIPMENT_ID ||
        key === ModalKey.ADD_GENERIC_ITEM ||
        key === ModalKey.EDIT_GENERIC_ITEM ||
        key === ModalKey.REQUEST_CHECKING ||
        key === ModalKey.CARD_ITEM_DETAILS;

      const props = isModalWithCustomProps ? args[1] : undefined;
      const options = isModalWithCustomProps ? args[2] : args[1];

      _setModal({
        key,
        props: props!,
        options: options || DEFAULT_OPTIONS,
      });
    },
    [_setModal],
  );

  const hideModal = useCallback(
    (modalKey: ModalKey) => {
      _removeModal(modalKey);
    },
    [_removeModal],
  );

  const showCardItemDetailsModal = useCallback(
    (modalProps: CardItemDetailsProps) => {
      showModal(ModalKey.CARD_ITEM_DETAILS, modalProps);
    },
    [showModal],
  );

  const showChangePasswordModal = useCallback(() => {
    showModal(ModalKey.CHANGE_PASSWORD);
  }, [showModal]);

  const showShipmentSelectAddressModal = useCallback(() => {
    showModal(ModalKey.SHIPMENT_SELECT_ADDRESS);
  }, [showModal]);

  const showShipmentSelectProductsModal = useCallback(() => {
    showModal(ModalKey.SHIPMENT_SELECT_PRODUCTS);
  }, [showModal]);

  const hideAllShipmentModals = useCallback(() => {
    hideModal(ModalKey.SHIPMENT_SELECT_ADDRESS);
    hideModal(ModalKey.SHIPMENT_SELECT_PRODUCTS);
    hideModal(ModalKey.SHIPMENT_ITEM_DETAILS);
    hideModal(ModalKey.SHIPMENT_ADD_ITEM_CODE);
    hideModal(ModalKey.SHIPMENT_SELECT_PACKAGING);
    hideModal(ModalKey.SHIPMENT_REMARKS);
    hideModal(ModalKey.SHIPMENT_RESUME);
    hideModal(ModalKey.RECEIVE_SELECT_CODE);
    hideModal(ModalKey.RECEIVE_SHIPMENT_ID);
  }, [hideModal]);

  const hideAllReceiveModals = useCallback(() => {
    hideModal(ModalKey.RECEIVE_SELECT_CODE);
    hideModal(ModalKey.RECEIVE_SHIPMENT_DETAILS);
    hideModal(ModalKey.RECEIVE_SHIPMENT_ID);
    hideModal(ModalKey.RECEIVE_SHIPMENT_REMARKS);
  }, [hideModal]);

  const showShipmentAddItemCodeModal = useCallback(
    (modalProps: ShipmentAddItemCodeProps) => {
      showModal(ModalKey.SHIPMENT_ADD_ITEM_CODE, modalProps);
    },
    [showModal],
  );

  const showShipmentItemDetailsModal = useCallback(
    (modalProps: ShipmentItemDetailsProps) => {
      showModal(ModalKey.SHIPMENT_ITEM_DETAILS, modalProps);
    },
    [showModal],
  );

  const showConfirmationModal = useCallback(
    (modalProps: ConfirmationProps) => {
      showModal(ModalKey.CONFIRMATION, modalProps);
    },
    [showModal],
  );

  const showRequestCheckingModal = useCallback(
    (modalProps: RequestCheckingProps) => {
      showModal(ModalKey.REQUEST_CHECKING, modalProps);
    },
    [showModal],
  );

  const showResumeConfirmationModal = useCallback(
    (modalProps: ResumeConfirmationProps) => {
      showModal(ModalKey.RESUME_CONFIRMATION, modalProps);
    },
    [showModal],
  );

  const showShipmentDetailsModal = useCallback(
    (modalProps: ShipmentDetailsProps) => {
      showModal(ModalKey.SHIPMENT_DETAILS, modalProps);
    },
    [showModal],
  );

  const showShipmentSelectPackagingModal = useCallback(() => {
    showModal(ModalKey.SHIPMENT_SELECT_PACKAGING);
  }, [showModal]);

  const showShipmentRemarksModal = useCallback(() => {
    showModal(ModalKey.SHIPMENT_REMARKS);
  }, [showModal]);

  const showShipmentResumeModal = useCallback(() => {
    showModal(ModalKey.SHIPMENT_RESUME);
  }, [showModal]);

  const showShipmentImagePreviewModal = useCallback(
    (modalProps: ShipmentImagePreviewProps) => {
      showModal(ModalKey.SHIPMENT_IMAGE_PREVIEW, modalProps);
    },
    [showModal],
  );

  const showShipmentAttachCommentModal = useCallback(
    (props: ShipmentAttachCommentProps) => {
      showModal(ModalKey.SHIPMENT_ATTACH_COMMENT, props);
    },
    [showModal],
  );

  const showAddOrganizationModal = useCallback(() => {
    showModal(ModalKey.ADD_ORGANIZATION);
  }, [showModal]);

  const showAddUserModal = useCallback(() => {
    showModal(ModalKey.ADD_USER);
  }, [showModal]);

  const showAddGenericItemModal = useCallback(
    (props: AddGenericItemProps) => {
      showModal(ModalKey.ADD_GENERIC_ITEM, props);
    },
    [showModal],
  );

  const showEditGenericItemModal = useCallback(
    (props: EditGenericItemProps) => {
      showModal(ModalKey.EDIT_GENERIC_ITEM, props);
    },
    [showModal],
  );

  // Notification

  const showNotificationModal = useCallback(() => {
    showModal(ModalKey.NOTIFICATION);
  }, [showModal]);

  // Pending shipment
  const showPendingShipmentRequestApprovalModal = useCallback(() => {
    showModal(ModalKey.PENDING_SHIPMENT_REQUEST_APPROVAL);
  }, [showModal]);

  const showPendingShipmentSelectProductsModal = useCallback(() => {
    showModal(ModalKey.PENDING_SHIPMENT_SELECT_PRODUCTS);
  }, [showModal]);

  const showPendingShipmentSelectPackagingModal = useCallback(() => {
    showModal(ModalKey.PENDING_SHIPMENT_SELECT_PACKAGING);
  }, [showModal]);

  const showPendingShipmentResumeModal = useCallback(() => {
    showModal(ModalKey.PENDING_SHIPMENT_RESUME);
  }, [showModal]);

  const hideAllPendingShipmentModals = useCallback(() => {
    hideModal(ModalKey.PENDING_SHIPMENT_REQUEST_APPROVAL);
    hideModal(ModalKey.PENDING_SHIPMENT_SELECT_PRODUCTS);
    hideModal(ModalKey.PENDING_SHIPMENT_SELECT_PACKAGING);
    hideModal(ModalKey.PENDING_SHIPMENT_RESUME);
  }, [hideModal]);

  const showReceiveSelectCodeModal = useCallback(() => {
    showModal(ModalKey.RECEIVE_SELECT_CODE);
  }, [showModal]);

  const showReceiveShipmentIdModal = useCallback(
    (props: ReceiveShipmentIdProps) => {
      showModal(ModalKey.RECEIVE_SHIPMENT_ID, props);
    },
    [showModal],
  );

  const showReceiveShipmentDetailsModal = useCallback(() => {
    showModal(ModalKey.RECEIVE_SHIPMENT_DETAILS);
  }, [showModal]);

  const showReceiveRemarksModal = useCallback(() => {
    showModal(ModalKey.RECEIVE_SHIPMENT_REMARKS);
  }, [showModal]);

  const memoizedValue = useMemo<ModalsContextProps>(
    () => ({
      modals,
      showModal,
      hideModal,
      showChangePasswordModal,
      showShipmentSelectAddressModal,
      showShipmentSelectProductsModal,
      hideAllShipmentModals,
      showConfirmationModal,
      showResumeConfirmationModal,
      showShipmentAddItemCodeModal,
      showShipmentItemDetailsModal,
      showShipmentSelectPackagingModal,
      showShipmentRemarksModal,
      showShipmentResumeModal,
      showShipmentImagePreviewModal,
      showAddOrganizationModal,
      showAddUserModal,
      showAddGenericItemModal,
      showShipmentDetailsModal,
      showNotificationModal,
      showEditGenericItemModal,
      showPendingShipmentRequestApprovalModal,
      showPendingShipmentSelectProductsModal,
      showPendingShipmentSelectPackagingModal,
      showPendingShipmentResumeModal,
      hideAllPendingShipmentModals,
      showReceiveSelectCodeModal,
      showReceiveShipmentIdModal,
      showReceiveShipmentDetailsModal,
      showShipmentAttachCommentModal,
      showReceiveRemarksModal,
      hideAllReceiveModals,
      showRequestCheckingModal,
      showCardItemDetailsModal,
    }),

    [
      modals,
      showModal,
      hideModal,
      showChangePasswordModal,
      showShipmentSelectAddressModal,
      showShipmentSelectProductsModal,
      hideAllShipmentModals,
      showConfirmationModal,
      showResumeConfirmationModal,
      showShipmentAddItemCodeModal,
      showShipmentItemDetailsModal,
      showShipmentSelectPackagingModal,
      showShipmentRemarksModal,
      showShipmentResumeModal,
      showShipmentImagePreviewModal,
      showAddOrganizationModal,
      showAddUserModal,
      showAddGenericItemModal,
      showShipmentDetailsModal,
      showNotificationModal,
      showPendingShipmentRequestApprovalModal,
      showPendingShipmentSelectProductsModal,
      showPendingShipmentSelectPackagingModal,
      showPendingShipmentResumeModal,
      hideAllPendingShipmentModals,
      showReceiveSelectCodeModal,
      showReceiveShipmentIdModal,
      showReceiveShipmentDetailsModal,
      showShipmentAttachCommentModal,
      showReceiveRemarksModal,
      hideAllReceiveModals,
      showEditGenericItemModal,
      showRequestCheckingModal,
      showCardItemDetailsModal,
    ],
  );

  return (
    <ModalsContext.Provider value={memoizedValue}>
      {children}
    </ModalsContext.Provider>
  );
};

export const useModalsContext = (): ModalsContextProps =>
  useContext(ModalsContext);
