import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return yup.object({
    firstName: yup.string().required(t('Form.errors.required')),
    lastName: yup.string().required(t('Form.errors.required')),
    organizations: yup.array().min(1).required(t('Form.errors.required')),
    role: yup.string().required(t('Form.errors.required')),
    email: yup
      .string()
      .required(t('Form.errors.required'))
      .email(t('Form.errors.email')),
    externalId: yup
      .string()
      .required(t('Form.errors.required'))
      .matches(/^\d{15}$/, t('Form.errors.invalidFormat')),
  });
};
