import React from 'react';

import { AddGenericItemModalProps } from '@organisms/Modals/AddGenericItemModal/definitions';
import { CardItemDetailsModalProps } from '@organisms/Modals/CardItemDetailsModal/definitions';
import { ConfirmationModalProps } from '@organisms/Modals/ConfirmationModal/definitions';
import { EditGenericItemModalProps } from '@organisms/Modals/EditGenericItemModal/definitions';
import { ReceiveShipmentIdModalProps } from '@organisms/Modals/ReceiveFlow/ReceiveShipmentIdModal/definitions';
import { RequestCheckingModalProps as RequestCheckingModalPropsInterface } from '@organisms/Modals/RequestCheckingModal/definitions';
import { ShipmentItemDetailsModalProps } from '@organisms/Modals/Shipment/ShipmentItemDetailsModal/definitions';
import { ShipmentImagePreviewModalProps } from '@organisms/Modals/Shipment/ShipmentRemarksModal/ShipmentImagePreviewModal/definitions';
import { ShipmentDetailsModalProps } from '@organisms/Modals/ShipmentDetailsModal/definitions';

export enum ModalKey {
  CHANGE_PASSWORD = 'change-password',
  SHIPMENT_SELECT_ADDRESS = 'shipment-select-address',
  SHIPMENT_SELECT_PRODUCTS = 'shipment-select-products',
  CONFIRMATION = 'confirmation',
  RESUME_CONFIRMATION = 'resume-confirmation',
  SHIPMENT_ITEM_DETAILS = 'item-details',
  SHIPMENT_ADD_ITEM_CODE = 'transfer-add-item-code',
  SHIPMENT_SELECT_PACKAGING = 'shipment-select-packaging',
  SHIPMENT_REMARKS = 'shipment-remarks',
  SHIPMENT_RESUME = 'shipment-resume',
  SHIPMENT_IMAGE_PREVIEW = 'shipment-image-preview',
  SHIPMENT_ATTACH_COMMENT = 'shipment-attach-comment',
  PENDING_SHIPMENT_REQUEST_APPROVAL = 'pending-shipment-request-approval',
  PENDING_SHIPMENT_SELECT_PRODUCTS = 'pending-shipment-select-products',
  PENDING_SHIPMENT_SELECT_PACKAGING = 'pending-shipment-select-packaging',
  PENDING_SHIPMENT_RESUME = 'pending-shipment-resume',
  ADD_ORGANIZATION = 'add-organization',
  ADD_USER = 'add-user',
  ADD_PRODUCT_CATEGORY = 'add-product-category',
  EDIT_PRODUCT_CATEGORY = 'edit-product-category',
  ADD_SUPPORT = 'add-support',
  EDIT_SUPPORT = 'edit-support',
  ADD_PACKAGING = 'add-packaging',
  EDIT_PACKAGING = 'edit-packaging',
  SHIPMENT_DETAILS = 'shipment-details',
  ADD_REASON_FOR_REQUEST = 'add-reason',
  NOTIFICATION = 'notification',
  ADD_BILLABLE_CENTER_COST = 'add-billable-center-cost',
  RECEIVE_SELECT_CODE = 'receive-select-code',
  RECEIVE_SHIPMENT_ID = 'receive-shipment-id',
  RECEIVE_SHIPMENT_DETAILS = 'receive-shipment-details',
  RECEIVE_SHIPMENT_REMARKS = 'receive-shipment-remarks',
  REQUEST_CHECKING = 'request-checking',

  ADD_GENERIC_ITEM = 'add-generic-item',
  EDIT_GENERIC_ITEM = 'edit-generic-item',

  CARD_ITEM_DETAILS = 'card-item-details',
}

export type ConfirmationProps = Pick<
  ConfirmationModalProps,
  | 'onConfirm'
  | 'title'
  | 'content'
  | 'primaryButtonProps'
  | 'secondaryButtonProps'
>;

export type RequestCheckingProps = Pick<
  RequestCheckingModalPropsInterface,
  'onConfirm' | 'title' | 'primaryButtonProps' | 'secondaryButtonProps'
>;

export type ResumeConfirmationProps = Pick<
  ConfirmationModalProps,
  'onConfirm' | 'title' | 'primaryButtonProps'
> & {
  code: string;
  content: string;
};

export type ShipmentDetailsProps = Pick<ShipmentDetailsModalProps, 'shipment'>;

export type ShipmentItemDetailsProps = Pick<
  ShipmentItemDetailsModalProps,
  'type' | 'product'
>;
export type ShipmentAddItemCodeProps = Pick<
  ShipmentItemDetailsProps,
  'product'
>;

export type CardItemDetailsProps = Pick<
  CardItemDetailsModalProps,
  'selectedItem'
>;

export type ShipmentImagePreviewProps =
  | Pick<ShipmentImagePreviewModalProps, 'image'>
  | undefined;

export type EditGenericItemProps = Pick<
  EditGenericItemModalProps,
  | 'categoryId'
  | 'updateItem'
  | 'getItem'
  | 'editItemLabel'
  | 'addEditItemLabel'
  | 'onEditItem'
  | 'getItemQueryKey'
>;

export type AddGenericItemProps = Pick<
  AddGenericItemModalProps,
  | 'createItem'
  | 'addItemLabelNs'
  | 'addEditItemLabelNs'
  | 'showImageField'
  | 'onItemCreated'
>;
export type ShipmentAttachCommentProps = {
  shipmentId: number;
  delayedComment: string | null;
};

export type ReceiveShipmentIdProps = Pick<
  ReceiveShipmentIdModalProps,
  'isQRCode'
>;

type ModalProps = {
  [ModalKey.CONFIRMATION]: ConfirmationProps;
  [ModalKey.RESUME_CONFIRMATION]: ResumeConfirmationProps;
  [ModalKey.SHIPMENT_ITEM_DETAILS]: ShipmentItemDetailsProps;
  [ModalKey.SHIPMENT_ADD_ITEM_CODE]: ShipmentAddItemCodeProps;
  [ModalKey.SHIPMENT_IMAGE_PREVIEW]: ShipmentImagePreviewProps;
  [ModalKey.SHIPMENT_DETAILS]: ShipmentDetailsProps;
  [ModalKey.SHIPMENT_ATTACH_COMMENT]: ShipmentAttachCommentProps;
  [ModalKey.RECEIVE_SHIPMENT_ID]: ReceiveShipmentIdProps;
  [ModalKey.ADD_GENERIC_ITEM]: AddGenericItemProps;
  [ModalKey.EDIT_GENERIC_ITEM]: EditGenericItemProps;
  [ModalKey.REQUEST_CHECKING]: RequestCheckingProps;
  [ModalKey.CARD_ITEM_DETAILS]: CardItemDetailsProps;
};

export type ModalOptions = {
  persistent?: boolean;
};

export type ShowModal = <T extends ModalKey>(
  ...args: T extends keyof ModalProps
    ? [modalKey: T, props: ModalProps[T], options?: ModalOptions]
    : [modalKey: T, options?: ModalOptions]
) => void;

export type ModalEntity = {
  key: ModalKey;
  props?: ModalProps[keyof ModalProps];
  options?: ModalOptions;
};

export interface ModalsContextProps {
  modals: Array<ModalEntity>;
  showModal: ShowModal;
  hideModal: (modalKey: ModalKey) => void;
  showChangePasswordModal: () => void;
  showShipmentSelectAddressModal: () => void;
  showShipmentSelectProductsModal: () => void;
  showShipmentAddItemCodeModal: (props: ShipmentAddItemCodeProps) => void;
  showShipmentItemDetailsModal: (props: ShipmentItemDetailsProps) => void;
  hideAllShipmentModals: () => void;
  hideAllReceiveModals: () => void;
  showConfirmationModal: (props: ConfirmationProps) => void;
  showResumeConfirmationModal: (props: ResumeConfirmationProps) => void;
  showShipmentDetailsModal: (props: ShipmentDetailsProps) => void;
  showShipmentSelectPackagingModal: () => void;
  showShipmentRemarksModal: () => void;
  showReceiveRemarksModal: () => void;
  showShipmentImagePreviewModal: (props: ShipmentImagePreviewProps) => void;
  showShipmentResumeModal: () => void;
  showShipmentAttachCommentModal: (props: ShipmentAttachCommentProps) => void;
  showPendingShipmentRequestApprovalModal: () => void;
  showPendingShipmentSelectProductsModal: () => void;
  showPendingShipmentSelectPackagingModal: () => void;
  showPendingShipmentResumeModal: () => void;
  hideAllPendingShipmentModals: () => void;
  showAddOrganizationModal: () => void;
  showAddUserModal: () => void;
  showAddGenericItemModal: (props: AddGenericItemProps) => void;
  showEditGenericItemModal: (props: EditGenericItemProps) => void;
  showNotificationModal: () => void;
  showReceiveSelectCodeModal: () => void;
  showReceiveShipmentIdModal: (props: ReceiveShipmentIdProps) => void;
  showReceiveShipmentDetailsModal: () => void;
  showRequestCheckingModal: (props: RequestCheckingProps) => void;
  showCardItemDetailsModal: (props: CardItemDetailsProps) => void;
}

export interface ModalsProviderProps {
  children?: React.ReactNode;
}
