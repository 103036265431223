import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useBillableCenterCostApi } from '@api/billableCenterCost';
import { useProductApi } from '@api/productApi';
import { useReasonForRequestApi } from '@api/reasonForRequestApi';
import { Textarea } from '@atoms/Textarea';
import { QueryKeys } from '@definitions/QueryKeys';
import { FormField } from '@molecules/Form/FormField';
import { Input } from '@molecules/Form/Input';
import { Radio } from '@molecules/Form/Radio';
import { BaseModal } from '@organisms/Modals/BaseModal';
import { CardItemDetailsModalProps } from '@organisms/Modals/CardItemDetailsModal/definitions';
import { skipToken, useQuery } from '@tanstack/react-query';
import { LanguageId } from '@translations/definitions';
import { noop } from '@utils/noop';

const CardItemDetailsModal = ({
  open,
  onClose,
  selectedItem,
}: CardItemDetailsModalProps) => {
  const { t, i18n } = useTranslation();

  // API hooks
  const { getProductCategory } = useProductApi();
  const { getBillableCenterCost } = useBillableCenterCostApi();
  const { getReasonForRequest } = useReasonForRequestApi();

  // Fetch product category data
  const { data: productCategory } = useQuery({
    queryKey: [
      QueryKeys.PRODUCT_CATEGORIES,
      selectedItem?.product.productCategoryId,
    ],
    queryFn: selectedItem?.product.productCategoryId
      ? () => getProductCategory(selectedItem.product.productCategoryId)
      : skipToken,
    enabled: open,
  });

  // Fetch billable center cost data
  const { data: billableCenterCost } = useQuery({
    queryKey: [
      QueryKeys.SHIPMENT_BILLABLE_CENTER_COST,
      selectedItem?.billableCenterCostId,
    ],
    queryFn: selectedItem?.billableCenterCostId
      ? () => getBillableCenterCost(Number(selectedItem.billableCenterCostId))
      : skipToken,
    enabled: open,
  });

  // Fetch reason for request data
  const { data: reasonForRequest } = useQuery({
    queryKey: [
      QueryKeys.SHIPMENT_REASON_FOR_REQUEST,
      selectedItem?.reasonForRequestId,
    ],
    queryFn: selectedItem?.reasonForRequestId
      ? () => getReasonForRequest(Number(selectedItem.reasonForRequestId))
      : skipToken,
    enabled: open,
  });

  // Memoized label for product category based on the current language
  const productCategoryLabel = useMemo(() => {
    const targetLanguage =
      i18n.language === 'en' ? LanguageId.EN : LanguageId.FR;
    return productCategory?.label.find(
      (label) => label.languageId.toString() === targetLanguage,
    )?.label;
  }, [productCategory, i18n.language]);

  // Memoized label for reason for request based on the current language
  const reasonForRequestLabel = useMemo(() => {
    const targetLanguage =
      i18n.language === 'en' ? LanguageId.EN : LanguageId.FR;
    return reasonForRequest?.labels.find(
      (label) => label.languageId.toString() === targetLanguage,
    )?.label;
  }, [reasonForRequest, i18n.language]);

  const isFullForm = useMemo(() => {
    return (
      selectedItem?.product.styleIdType !== 'sts' &&
      selectedItem?.product.styleIdType !== 'consignment'
    );
  }, [selectedItem?.product.styleIdType]);

  return (
    <BaseModal
      id={'CardItemDetailsModal'}
      open={open}
      onClose={onClose}
      showCloseButton
      title={t('Modals.ItemDetails.title', {
        type: selectedItem?.product.styleIdType?.toUpperCase(),
      })}
      size="medium"
    >
      <form className="flex flex-col gap-4">
        {/* Display Style ID */}
        <FormField
          label={
            isFullForm
              ? t('Modals.ItemDetails.StyleID')
              : t(
                  `Modals.ItemDetails.StyleID_${selectedItem?.product.styleIdType}`,
                )
          }
          disabled
        >
          <Input
            onChange={noop}
            disabled
            placeholder={selectedItem?.product.styleId}
          />
        </FormField>

        {/* Display Product Category */}
        {isFullForm && (
          <FormField label={t('Modals.ItemDetails.Category')} disabled>
            <Input
              onChange={noop}
              disabled
              placeholder={productCategoryLabel}
            />
          </FormField>
        )}

        {/* Display Name of Product */}
        {isFullForm && (
          <FormField label={t('Modals.ItemDetails.NameOfProduct')} disabled>
            <Input
              onChange={noop}
              disabled
              placeholder={selectedItem?.product.name}
            />
          </FormField>
        )}

        {/* Display Size */}
        {isFullForm && (
          <FormField label={t('Modals.ItemDetails.Size')} disabled>
            <Input
              onChange={noop}
              disabled
              placeholder={selectedItem?.product.size}
            />
          </FormField>
        )}

        {/* Display Billable Status */}
        <FormField label={t('Modals.ItemDetails.Billable')} disabled>
          <div className="flex items-center gap-2">
            <Radio
              value="true"
              onChange={noop}
              checked={selectedItem?.billable === true}
              disabled
            />
            <label className="ui-radio-label text-Primary-03">
              {t('General.yes')}
            </label>
            <Radio
              value="false"
              onChange={noop}
              checked={selectedItem?.billable === false}
              disabled
            />
            <label className="ui-radio-label text-Primary-03">
              {t('General.no')}
            </label>
          </div>
        </FormField>

        {/* Display Billable Center Cost if Billable */}
        {selectedItem?.billable && (
          <FormField
            label={t('Modals.ItemDetails.BillableCenterCost')}
            disabled
          >
            <Input
              onChange={noop}
              disabled
              placeholder={billableCenterCost?.name}
            />
          </FormField>
        )}

        {/* Display Reason for Request */}
        <FormField label={t('Modals.ItemDetails.ReasonForRequest')} disabled>
          <Input onChange={noop} disabled placeholder={reasonForRequestLabel} />
        </FormField>

        {/* Display Comment */}
        <FormField
          label={t('Modals.ItemDetails.Comment')}
          disabled
          direction={'column'}
        >
          <Textarea
            disabled
            placeholder={selectedItem?.comment ? selectedItem?.comment : ''}
            onChange={noop}
          />
        </FormField>
      </form>
    </BaseModal>
  );
};

export default React.memo(CardItemDetailsModal);
