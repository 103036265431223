import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { useShipmentApi } from '@api/ShipmentApi';
import { useModalsContext } from '@contexts/modals';
import { ModalKey } from '@contexts/modals/definitions';
import { useShipmentContext } from '@contexts/shipment';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import DatesSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Dates';
import OrganizationsSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Organizations';
import PackagingSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Packaging';
import ProductsSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Products';
import RemarksSection from '@organisms/Modals/GenericShipmentResumeModal/Sections/Remarks';
import { ShipmentBaseModal } from '@organisms/Modals/Shipment/ShipmentBaseModal';
import { useMutation } from '@tanstack/react-query';
import { isNullOrUndefined } from '@utils/typeCheck';

import { ShipmentResumeModalProps } from './definitions';

const ShipmentResumeModal: React.FC<ShipmentResumeModalProps> = ({
  open,
  onClose,
}) => {
  const { invalidateAllShipmentQueries } = useInvalidateQueries();
  const { t } = useTranslation();
  const { showResumeConfirmationModal, hideAllShipmentModals, hideModal } =
    useModalsContext();
  const { createShipment } = useShipmentApi();
  const { mutate: callCreateShipment, isPending: isPendingCallCreateShipment } =
    useMutation({
      mutationFn: createShipment,
    });

  const {
    flow,
    organizationId,
    products,
    packagings,
    supports,
    remarks,
    reset: resetShipmentContext,
    setOrganizationId,
  } = useShipmentContext();

  const isNextDisabled =
    isPendingCallCreateShipment || isNullOrUndefined(organizationId);

  const handleNext = useCallback(() => {
    callCreateShipment(
      {
        flow: flow === 'transfer' ? 'return' : 'request',
        organizationId: organizationId,
        products: products.map((product) => {
          // rewrite id to undefined if it has a negative id (manually added product)
          const calculatedId =
            !isNullOrUndefined(product) &&
            !isNullOrUndefined(product.productId) &&
            product.productId > 0
              ? product.productId
              : undefined;

          return {
            ...product,
            productId: calculatedId,
          };
        }),
        packagings: packagings.map((packaging) => ({
          ...packaging,
          packagingId: packaging.id,
        })),
        supports: supports.map((support) => ({
          ...support,
          supportId: support.id,
        })),
        image: remarks?.image,
        comment: remarks?.comment,
        receiverName: remarks?.recipientName,
        isUrgent: remarks?.urgentDelivery || false,
        desiredDeliveryDate: dayjs(remarks?.desideredDeliveryDate).format(
          'YYYY-MM-DD',
        ),
      },
      {
        onSuccess: (res) => {
          hideAllShipmentModals();
          resetShipmentContext();

          showResumeConfirmationModal({
            onConfirm: () => {
              onClose?.();
            },
            code: res.code,
            content: t(
              'Modals.ShipmentConfirmation.CreateShipmentSuccess.title',
            ),
          });

          invalidateAllShipmentQueries();
        },
      },
    );
  }, [
    callCreateShipment,
    flow,
    hideAllShipmentModals,
    invalidateAllShipmentQueries,
    onClose,
    organizationId,
    packagings,
    products,
    remarks?.comment,
    remarks?.desideredDeliveryDate,
    remarks?.image,
    remarks?.urgentDelivery,
    remarks?.recipientName,
    resetShipmentContext,
    showResumeConfirmationModal,
    supports,
    t,
  ]);

  const handleEditSection = useCallback(
    (sectionType: string) => () => {
      hideModal(ModalKey.SHIPMENT_RESUME);

      if (sectionType === 'remarks') return;

      hideModal(ModalKey.SHIPMENT_REMARKS);

      if (sectionType === 'packagings') return;

      hideModal(ModalKey.SHIPMENT_SELECT_PACKAGING);
    },
    [hideModal],
  );

  return (
    <ShipmentBaseModal
      id="shipment-resume-modal"
      title={t('Modals.GenericShipmentResume.title')}
      open={open}
      onNext={handleNext}
      onClose={onClose}
      isNextDisabled={isNextDisabled}
    >
      <div className="flex flex-col gap-4">
        <OrganizationsSection
          isRequiredAt={flow !== 'transfer'}
          organizationId={organizationId}
          onChangeOrganizationId={setOrganizationId}
        />
        <ProductsSection
          onEdit={handleEditSection('products')}
          products={products}
        />
        {flow === 'transfer' ? (
          <PackagingSection
            onEdit={handleEditSection('packagings')}
            packagings={packagings}
          />
        ) : (
          <DatesSection remarks={remarks} />
        )}
      </div>

      {remarks && (
        <div className="mt-7">
          <RemarksSection
            remarks={remarks}
            showAttachments={flow === 'transfer' && !!remarks?.image}
            onEdit={handleEditSection('remarks')}
          />
        </div>
      )}
    </ShipmentBaseModal>
  );
};

export default React.memo(ShipmentResumeModal);
