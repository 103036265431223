import { StylesConfig } from 'react-select';

export const multiSelectStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    boxShadow: '0 !important',
    border: 'none',
    borderBottom: '1px solid black',
    borderRadius: 0,
    '&:hover': {
      border: 'none',
      borderBottom: '1px solid black',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#B1B1B1',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    border: '1px solid #CAC4D0',
    borderRadius: 8,
  }),
};
