import React, { useCallback, useState } from 'react';

import { IconSVG } from '@atoms/IconSVG';
import { Typography } from '@atoms/Typography';
import { CounterAction } from '@definitions/Counter';
import { ItemCounterProps } from '@molecules/ItemCounter/definitions';

const ItemCounter: React.FC<ItemCounterProps> = ({
  startValue,
  icon,
  label,
  onChange,
}) => {
  const [count, setCount] = useState(startValue || 0);

  const handleChange = useCallback(
    (action: CounterAction) => {
      let newCount = count;
      if (action === 'increment') {
        newCount = count < 9999 ? count + 1 : 9999;
      }

      if (action === 'decrement') {
        newCount = Math.max(0, count - 1);
      }

      setCount(newCount);

      onChange?.(newCount);
    },
    [count, onChange],
  );

  return (
    <div className="flex items-center">
      <div className="w-full flex gap-8">
        {icon?.name ? (
          <IconSVG icon={icon.name} size={24} />
        ) : icon?.pathName ? (
          <img src={icon.pathName} alt={label} className="w-[24px]" />
        ) : null}
        <Typography size="lg" isBold className="w-full">
          {label}
        </Typography>
      </div>

      <div className="flex">
        <button
          type="button"
          className="m-2"
          onClick={() => handleChange('decrement')}
        >
          <IconSVG icon="minus" size={14} />
        </button>
        <div className="w-10 flex text-center">
          <input
            type="number"
            value={count.toString().padStart(2, '0')}
            onChange={(e) => {
              const newValue =
                Number(e.target.value) <= 9999 ? Number(e.target.value) : 9999;
              setCount(newValue);
              onChange?.(newValue);
            }}
            name={label}
            id={label}
            className="w-[40px] text-center focus:outline-none"
          />
        </div>
        <button
          type="button"
          className="m-2"
          onClick={() => handleChange('increment')}
        >
          <IconSVG icon="plus" size={14} />
        </button>
      </div>
    </div>
  );
};

export default ItemCounter;
