import React, { useCallback, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'use-debounce';

import { useOrganizationsApi } from '@api/OrganizationsApi';
import { useRoleApi } from '@api/RoleApi';
import { useUserApi } from '@api/UserApi';
import { Button } from '@atoms/Button';
import { Typography } from '@atoms/Typography';
import { QueryKeys } from '@definitions/QueryKeys';
import { yupResolver } from '@hookform/resolvers/yup';
import useToast from '@hooks/useToast';
import { FormField } from '@molecules/Form/FormField';
import { Input } from '@molecules/Form/Input';
import { Select } from '@molecules/Form/Select';
import { MultiSelect } from '@molecules/MultiSelect';
import { BaseModal } from '@organisms/Modals/BaseModal';
import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { AddUserModalProps, FormValues } from './definitions';
import { useValidationSchema } from './validation';

const SELECTABLE_ORGANIZATIONS_LIMIT: number = 3;

const AddUserModal = ({ open, onClose }: AddUserModalProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { showSuccessToast, showWarningToast } = useToast();

  const validation = useValidationSchema();

  const { getOrganizationList } = useOrganizationsApi();

  const { createUser } = useUserApi();

  const { getRoleList } = useRoleApi();

  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(validation),
    defaultValues: {
      externalId: '',
      firstName: '',
      lastName: '',
      organizations: [],
      role: '',
      email: '',
    },
  });

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  const [searchValue, setSearchValue] = useState('');
  const [apiSearchValue] = useDebounce(searchValue, 300);
  const parsedApiSearchValue = useMemo(() => {
    const hasMinimumCharaters = apiSearchValue.length >= 3;

    return hasMinimumCharaters ? apiSearchValue : '';
  }, [apiSearchValue]);

  const {
    data: organizationListData,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    enabled: open,
    queryKey: [QueryKeys.ORGANIZATION_LIST, parsedApiSearchValue],
    queryFn: ({ pageParam = 1 }) =>
      getOrganizationList({
        page: pageParam,
        perPage: 20,
        textFilter: parsedApiSearchValue || undefined,
      }),
    placeholderData: keepPreviousData,
    initialPageParam: 1,
    getNextPageParam: (thisPage) =>
      thisPage.meta.currentPage + 1 > thisPage.meta.lastPage
        ? null
        : thisPage.meta.currentPage + 1,
  });

  const { data: roleListData } = useQuery({
    queryKey: [QueryKeys.ROLE_LIST],
    queryFn: () => getRoleList(),
    enabled: open,
  });

  const { mutate: callCreateUser, isPending: isAddingUser } = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      showSuccessToast(t('Modals.AddUser.successMessage'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.USER_LIST],
        exact: false,
      });
      handleClose();
    },
    onError: () => {
      showWarningToast(t('Modals.AddUser.errorMessage'));
    },
  });

  const flattenedOrganizationList = useMemo(() => {
    if (!organizationListData) {
      return [];
    }

    return organizationListData.pages.flatMap((page) => page.data);
  }, [organizationListData]);

  const organizationOptions = useMemo(() => {
    if (!flattenedOrganizationList) {
      return [];
    }

    return flattenedOrganizationList.map((item) => ({
      value: String(item.id),
      label: item.name,
    }));
  }, [flattenedOrganizationList]);

  const roleOptions = useMemo(() => {
    if (!roleListData) {
      return [];
    }

    return roleListData.map((item) => ({
      value: String(item.id),
      label: item.name,
    }));
  }, [roleListData]);

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    (values) => {
      callCreateUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        roleId: Number(values.role),
        organizationIds: values.organizations.map((item) => Number(item.value)),
        externalId: values.externalId,
      });
    },
    [callCreateUser],
  );

  const handleBottomReached = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <BaseModal
      id="addUserModal"
      title={t('Modals.AddUser.title')}
      size="medium"
      showCloseButton={true}
      open={open}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-9 items-stretch">
        <div className="flex flex-col gap-6 items-stretch">
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.firstName.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Modals.AddUser.fields.firstName.placeholder')}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.lastName.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Modals.AddUser.fields.lastName.placeholder')}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="organizations"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={t('Modals.AddUser.fields.organization.label')}
                required={true}
              >
                <MultiSelect
                  setSearchValue={setSearchValue}
                  onChange={onChange}
                  options={organizationOptions}
                  disabled={isAddingUser}
                  handleBottomReached={handleBottomReached}
                  value={value.map((item) => String(item.value))}
                  maxSelectableOptions={SELECTABLE_ORGANIZATIONS_LIMIT}
                  placeholder={t(
                    'Modals.AddUser.fields.organization.placeholder',
                  )}
                />
                {error?.message && (
                  <Typography
                    color="text-System-red"
                    size="xs"
                    sizeMd="sm"
                    className="mt-1"
                  >
                    {error.message}
                  </Typography>
                )}
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="externalId"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.externalId.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t(
                    'Modals.AddUser.fields.externalId.placeholder',
                  )}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="role"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField
                label={t('Modals.AddUser.fields.role.label')}
                required={true}
              >
                <Select
                  value={value}
                  onChange={onChange}
                  placeholder={t('Modals.AddUser.fields.role.placeholder')}
                  options={roleOptions}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <FormField
                label={t('Modals.AddUser.fields.email.label')}
                required={true}
              >
                <Input
                  name={name}
                  value={value}
                  onChange={onChange}
                  placeholder={t('Modals.AddUser.fields.email.placeholder')}
                  error={error?.message}
                  disabled={isAddingUser}
                />
              </FormField>
            )}
          />
        </div>
        <Button
          type="primary"
          label={t('General.ok')}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </BaseModal>
  );
};

export default React.memo(AddUserModal);
