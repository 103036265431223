import React, { useState } from 'react';
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  components,
} from 'react-select';

import { IconSVG } from '@atoms/IconSVG';
import { MultiSelectProps } from '@molecules/MultiSelect/definitions';
import colors from '@theme/colors';

import { multiSelectStyles } from './MultiSelectStyle';

const MultiSelect = ({
  value,
  options,
  onChange,
  setSearchValue,
  handleBottomReached,
  disabled = false,
  maxSelectableOptions = 3,
  placeholder,
}: MultiSelectProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const ClearIndicator: React.FC<ClearIndicatorProps> = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <IconSVG
          icon="close-circle"
          size={20}
          color={colors['Primary-03']}
          className="cursor-pointer"
        />
      </components.ClearIndicator>
    );
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconSVG
          icon={menuOpen ? 'chevron-up' : 'chevron-down'}
          size={20}
          color={colors['Primary-00']}
          className="cursor-pointer"
        />
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      isMulti
      name="organizations"
      onInputChange={setSearchValue}
      onChange={onChange}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      isDisabled={disabled}
      onMenuScrollToBottom={handleBottomReached}
      isOptionDisabled={() => value.length >= maxSelectableOptions}
      placeholder={placeholder}
      maxMenuHeight={160}
      styles={multiSelectStyles}
      components={{
        ClearIndicator,
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      onMenuOpen={() => setMenuOpen(true)}
      onMenuClose={() => setMenuOpen(false)}
    />
  );
};

export default React.memo(MultiSelect);
