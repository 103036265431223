import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Textarea } from '@atoms/Textarea';
import { Typography } from '@atoms/Typography';
import BaseModal from '@organisms/Modals/BaseModal/BaseModal';
import { RequestCheckingModalProps } from '@organisms/Modals/RequestCheckingModal/definitions';

const RequestCheckingModal: React.FC<RequestCheckingModalProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  primaryButtonProps,
  secondaryButtonProps,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState<string>('');

  // Empty the comment state before closing the modal
  const performClose = useCallback(() => {
    setComment('');
    onClose();
  }, [onClose]);

  return (
    <BaseModal
      id="requestCheckingModal"
      size="small"
      open={open}
      onClose={performClose}
      title={title}
      showCloseButton={true}
      footer={
        <div className="flex flex-col gap-2">
          {!primaryButtonProps?.hide && (
            <Button
              type="primary"
              label={primaryButtonProps?.label || t('General.yes')}
              onClick={() => {
                onConfirm(comment);
                performClose();
              }}
            />
          )}
          {!secondaryButtonProps?.hide && (
            <Button
              type="secondary"
              label={secondaryButtonProps?.label || t('General.no')}
              onClick={performClose}
            />
          )}
        </div>
      }
    >
      <Typography
        className="mb-4"
        size="sm"
        sizeMd="md"
        color="text-Primary-02"
      >
        {t('Modals.RequestCheckingModal.content')}
      </Typography>
      <Textarea
        name="checking-comment"
        placeholder={t('Modals.RequestCheckingModal.commentPlaceholder')}
        value={comment}
        onChange={setComment}
        maxLength={250}
      />
    </BaseModal>
  );
};

export default React.memo(RequestCheckingModal);
