import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useShipmentApi } from '@api/ShipmentApi';
import { Button } from '@atoms/Button';
import { useModalsContext } from '@contexts/modals';
import { usePendingShipmentContext } from '@contexts/pendingShipment';
import { QueryKeys } from '@definitions/QueryKeys';
import { Shipment } from '@definitions/Shipment';
import { useInvalidateQueries } from '@hooks/useInvalidateQueries';
import useToast from '@hooks/useToast';
import { useMutation, useQuery } from '@tanstack/react-query';
import { noop } from '@utils/noop';

import ShipmentsListPage from './ShipmentsListPage';

const PendingPage = () => {
  const { invalidateAllShipmentQueries } = useInvalidateQueries();
  const { t } = useTranslation();
  const { getShipmentsPending, refuseShipment, checkShipment } =
    useShipmentApi();
  const {
    showResumeConfirmationModal,
    showConfirmationModal,
    showRequestCheckingModal,
    showPendingShipmentRequestApprovalModal,
  } = useModalsContext();
  const { setSelectedShipment: ctxSetSelectedShipment } =
    usePendingShipmentContext();
  const { showWarningToast } = useToast();

  const { mutate: callRefuseShipment } = useMutation({
    mutationFn: refuseShipment,
  });

  const { mutate: callCheckShipment } = useMutation({
    mutationFn: checkShipment,
  });

  const { data: pendingShipments, isFetching } = useQuery({
    queryKey: [QueryKeys.SHIPMENTS_PENDING],
    queryFn: getShipmentsPending,
    refetchOnMount: true,
  });

  const handleRefuseShipment = useCallback(
    (id: number, code: string) => {
      showConfirmationModal({
        title: t('Modals.RefusePendingShipment.title'),
        content: t('Modals.RefusePendingShipment.content'),
        onConfirm: () => {
          callRefuseShipment(
            { shipmentID: id },
            {
              onSuccess: () => {
                showResumeConfirmationModal({
                  content: t('Modals.RefusePendingShipment.success'),
                  code: code!,
                  onConfirm: noop,
                });

                invalidateAllShipmentQueries();
              },
              onError: () => {
                showWarningToast(t('Modals.RefusePendingShipment.error'));
              },
            },
          );
        },
      });
    },
    [
      callRefuseShipment,
      invalidateAllShipmentQueries,
      showConfirmationModal,
      showResumeConfirmationModal,
      showWarningToast,
      t,
    ],
  );

  const handleCheckingShipment = useCallback(
    (id: number, code: string) => {
      showRequestCheckingModal({
        title: t('Modals.RequestCheckingModal.title'),
        onConfirm: (comment: string) => {
          callCheckShipment(
            { shipmentID: id, comment },
            {
              onSuccess: () => {
                showResumeConfirmationModal({
                  onConfirm: noop,
                  code: code!,
                  content: t('Modals.RequestCheckingModal.success'),
                });

                invalidateAllShipmentQueries();
              },
              onError: () => {
                showWarningToast(t('Modals.RequestCheckingModal.error'));
              },
            },
          );
        },
      });
    },
    [callCheckShipment, invalidateAllShipmentQueries, showRequestCheckingModal, showResumeConfirmationModal, showWarningToast, t],
  );

  const handleApproveShipment = useCallback(
    (shipment: Shipment) => {
      ctxSetSelectedShipment(shipment);
      showPendingShipmentRequestApprovalModal();
    },
    [ctxSetSelectedShipment, showPendingShipmentRequestApprovalModal],
  );

  return (
    <ShipmentsListPage
      title={t('Pending.title')}
      isLoading={isFetching}
      shipmentsList={pendingShipments}
      FiltersNode={undefined}
      canChangeActivityCardStatus={false}
      cardProps={{
        renderFooter: (shipment) => {
          const { id, code, status } = shipment;
          return (
            <div className="w-full flex gap-2 mt-6">
              <Button
                type="secondary"
                label={t('General.refuse')}
                className="flex-1"
                onClick={() => handleRefuseShipment(id, code!)}
              />
              {status === 'checking' ? (
                <Button
                  type="primary"
                  label={t('General.approve')}
                  className="flex-1"
                  onClick={() => handleApproveShipment(shipment)}
                />
              ) : (
                <Button
                  type="primary"
                  label={t('General.checking')}
                  className="flex-1"
                  onClick={() => handleCheckingShipment(id, code!)}
                />
              )}
            </div>
          );
        },
      }}
    />
  );
};
export default PendingPage;
