export enum NotificationSettingsStatus {
  Pending = 'Pending',
  Checking = 'Checking',
  Validated = 'Validated',
  Moving = 'Moving',
  In_Transit = 'In_Transit',
  Arrived = 'Arrived',
  Delivered = 'Delivered',
  Refused = 'Refused',
}

export type GetNotificationSettingsResponse = {
  id: number;
  userId: number;
} & {
  [K in keyof typeof NotificationSettingsStatus as `sender${K}`]: boolean;
} & {
  [K in keyof typeof NotificationSettingsStatus as `receiver${K}`]: boolean;
};

export type UpdateNotificationSettingsPayload = {
  settingField: Lowercase<
    | `sender_${NotificationSettingsStatus}`
    | `receiver_${NotificationSettingsStatus}`
  >;
  enabled: boolean;
};
