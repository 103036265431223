import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@atoms/Button';
import { Checkbox } from '@atoms/Checkbox';
import { Typography } from '@atoms/Typography';
import { useUserContext } from '@contexts/user';
import useWindow from '@hooks/useWindow';
import {
  DateRangeFilter,
  DateRangeFilterValue,
} from '@molecules/Filters/DateRangeFilter';
import { OrganizationIdFilter } from '@molecules/Filters/OrganizationId';
import { OrganizationTypeFilter } from '@molecules/Filters/OrganizationType';

import {
  FilterKeys,
  FiltersData,
  OrganizationsFiltersProps,
} from './definitions';

const ORGANIZATIONS_INITIAL_FILTERS: FiltersData = {
  organizationType: '',
  organizationId: '',
  dates: '',
  excludeCancelled: false,
};

const OrganizationsFilters = ({
  onFiltersChange,
}: OrganizationsFiltersProps) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();

  const { isAdmin } = useUserContext();

  const [filters, setFilters] = useState<FiltersData>(
    ORGANIZATIONS_INITIAL_FILTERS,
  );

  const updateFilters = useCallback((key: FilterKeys, value: string | boolean) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleApply = useCallback(() => {
    onFiltersChange(filters);
  }, [filters, onFiltersChange]);

  const handleReset = useCallback(() => {
    setFilters(ORGANIZATIONS_INITIAL_FILTERS);
    onFiltersChange(null);
  }, [onFiltersChange]);

  const FiltersNode = useMemo(() => {
    const dateRangeFilterValue = (
      filters.dates ? filters.dates.split(';') : [null, null]
    ) as DateRangeFilterValue;

    const handleDateRangeFilterChange = (value: DateRangeFilterValue) => {
      const parsedValue = value.filter(Boolean);
      updateFilters(
        'dates',
        parsedValue.length > 0 ? parsedValue.join(';') : '',
      );
    };

    return isAdmin ? (
      <Fragment>
        <div className="flex-1">
          <OrganizationTypeFilter
            value={filters.organizationType}
            onChange={(value) => updateFilters('organizationType', value)}
          />
        </div>
        <div className="flex-1">
          <OrganizationIdFilter
            value={filters.organizationId}
            onChange={(value) => updateFilters('organizationId', value)}
          />
        </div>
        <div className="flex-1">
          <DateRangeFilter
            value={dateRangeFilterValue}
            onChange={handleDateRangeFilterChange}
          />
        </div>
        <div className="flex-1 flex flex-row">
          <label className="flex gap-2 items-center cursor-pointer">
            <Checkbox
              name="excludeCancelled"
              value="excludeCancelled"
              checked={filters.excludeCancelled}
              onChange={(_, checked) => updateFilters('excludeCancelled', checked)}
              aria-labelledby={t('Filters.excludeCancelled.label')}
              size="xs"
            />
            <Typography size="md" color="text-Primary-02">
              {t('Filters.excludeCancelled.label')}
            </Typography>
          </label>
        </div>
      </Fragment>
    ) : (
      <DateRangeFilter
        value={dateRangeFilterValue}
        onChange={handleDateRangeFilterChange}
      />
    );
  }, [filters, isAdmin, updateFilters, t]);

  const isButtonDisabled = useMemo(() => {
    return Object.values(filters).every((value) => !value);
  }, [filters]);

  const ButtonsNode = useMemo(() => {
    return (
      <div
        className={`flex ${isAdmin ? 'flex-col' : 'flex-row-reverse'} gap-2 ${isMobile ? 'flex-col w-full' : ''}`}
      >
        <Button
          type="primary"
          label={t('General.apply')}
          onClick={handleApply}
          disabled={isButtonDisabled}
        />
        <Button
          type="secondary"
          label={t('General.clearAll')}
          onClick={handleReset}
          disabled={isButtonDisabled}
        />
      </div>
    );
  }, [handleApply, handleReset, isAdmin, isButtonDisabled, isMobile, t]);

  return (
    <div
      className={`w-full flex flex-row gap-6 justify-between ${isAdmin ? 'items-center' : 'items-end'} ${isMobile ? 'flex-col items-start' : ''}`}
    >
      <div
        className={`flex ${isMobile ? 'flex-col gap-3 w-full' : 'flex-row gap-6 items-center'}`}
      >
        {FiltersNode}
      </div>
      {ButtonsNode}
    </div>
  );
};

export default React.memo(OrganizationsFilters);
